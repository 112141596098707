import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgDelete, SvgImg, SvgSave, SvgSetaRight } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Reg_UserDash } from "services/RegisterData";

export default function Page_Users_Details(){

    const [ loading, setLoading ]   = useState(false);

    const [ userId, setUserId ]         = useState(GetUserData('id'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));

    const [ dataPage, setDataPage ] = useState(GetDataPage("accessDash"));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ status, setStatus ] = useState(InitialData('status'));
    const [ name, setName ]     = useState(InitialData('name'));
    const [ email, setEmail ]   = useState(InitialData('email'));
    const [ pass, setPass ]     = useState('');
    const [ file, setFile ]     = useState(InitialData('file'));
    const [ nivel, setNivel ]   = useState(InitialData('nivel'));
    const [ note, setNote ]     = useState(InitialData('note'));
    const [ page, setPage ]     = useState(InitialData('page'));

    const [ changesMade, setChangesMade ] = useState(InitialData('changesMade'));

    const [ statusFile, setStatusFile ] = useState(false);

    const [ addPage, setAddPage ] = useState([]);
    const [ opt, setOpt ]         = useState([
        { "value": "about_chroma/0", "label": "Sobre a chroma" },
        { "value": "client/1", "label": "Cliente" },
        { "value": "portfolio/2", "label": "Portfólio" },
        { "value": "proposal/3", "label": "Proposta" },
        { "value": "services/4", "label": "Serviços" },
        { "value": "budget/5", "label": "Orçamentos" },
        { "value": "report/6", "label": "Relatório" }
    ]);
    const [ statusPage, setStatusPage ] = useState(false);

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterUserData('access', setUserAccess);
        RegisterDataPage('accessDash', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("accessDash"));
        setIdPage(GetListPag('currentPageId'));

        setStatus(InitialData('status'));
        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setFile(InitialData('file'));
        setNivel(InitialData('nivel'));
        setNote(InitialData('note'));
        setPage(InitialData('page'));
        setChangesMade(InitialData('changesMade'));
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage("accessDash"));
        setIdPage(GetListPag('currentPageId'));

        setStatus(InitialData('status'));
        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setFile(InitialData('file'));
        setNivel(InitialData('nivel'));
        setNote(InitialData('note'));
        setPage(InitialData('page'));
        setChangesMade(InitialData('changesMade'));
    }, [idPage]);


    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'page'){
            return [];
        }
        return '';
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setStatusFile(true);
        }else {
            setStatusFile(false);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "accessDash", "type" : "delete_page", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_UserDash(userId, idPage, name, email, pass, file, nivel, note, addPage, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setPass('');
        setAddPage([]);
        setLoading(false);
        setStatusFile(false);
        setStatusPage(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setPass('');
        setAddPage([]);
        setLoading(false);
        setStatusFile(false);
        setStatusPage(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'accessDash');
        SetListPag('currentPageId', 'remuve');
    }
    
    return(
        <div className="Page_Users_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            User
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Details
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            To save
                        </div>
                    </button>
                </div>

                <div className="show_page_data">
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="16" required />
                            <span className="name_input">Name</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email.replaceAll(' ', '') } maxLength="60" required />
                            <span className="name_input">Email</span>
                        </div>
                        <div className="div_input">
                            {
                                idPage !=0 ?
                                <input type="password" className="pass" onChange={ (e)=>{ setPass(e.target.value) } } maxLength="20" value={ pass } /> :
                                <input type="password" className="pass" onChange={ (e)=>{ setPass(e.target.value) } } maxLength="20" required />
                            }
                            <span className="name_input">Password</span>
                        </div>
                        {
                            idPage == userId ? null :
                            <div className="div_input">
                                <select className="data_select" onChange={ (e)=>{ setNivel(e.target.value) } } value={ nivel }>
                                    <option value="0">User</option>
                                    <option value="1">Administrator</option>
                                </select>
                                <span className="name_input">Acesso</span>
                            </div>
                        }
                        <div className={ statusFile == true ? "div_input new_file_add new_file_add_active" : "div_input new_file_add" }>
                            <label>
                                { statusFile == true ? "Image added" : "Add image" }
                                <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                            </label>
                        </div>
                        {
                            file !='' ?
                                statusFile == true ?
                                <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                    <SvgImg color="#324d6b" className="icons"/>
                                </div> :
                                <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg', "login/" + file) } }>
                                    <SvgImg color="#324d6b" className="icons"/>
                                </div>
                            : null
                        }
                    </div>
                    <div className="list_input_data">   
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Observation</div>
                            <JoditEditor config={ config } value={ note ? note.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setNote(newContent) } />
                        </div>
                    </div>
                </div>

                {
                    idPage == 0 ? null :
                        nivel == 1 ? null :
                        <div className="show_page_data">
                            <div className="list_type_data">
                                <div className="type_title">Access to pages</div>
                                <div className="new_data_page" onClick={ ()=>{ setStatusPage(!statusPage); } }>
                                    { statusPage == true ? "Cancel" : "Release new page" }
                                </div>
                            </div>                            
                            {
                                statusPage == true ?
                                <div className="list_input_data" style={ { marginBottom: '16px' } }>
                                    <div className="div_space space_div">
                                        <Select className="div_space space_div" defaultOptions options={ opt } onChange={ (e)=> { setAddPage(e); } } value={ addPage } placeholder="Click here to view services..." isMulti />
                                    </div>
                                </div>
                                : null
                            }
                            <div className="list_name_page">
                                {
                                    page.length > 0 ?
                                        page.map((key, index)=>{
                                            return(
                                                <div className="div_name_page_access" key={ index }>
                                                    <div className="">
                                                        { key.name }
                                                    </div>
                                                    <div className="" onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                        <SvgDelete color="#f00000" className="icons" />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    :
                                    statusPage == true ? null : <div className="no_data">No pages released yet...</div>
                                }
                            </div>
                        </div>
                }
            </form>
        </div>
    )
}