import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListPag } from 'interface/Page';
import { SetUserData } from 'interface/Users';
import { SetListDataSingle } from 'interface/Data';

import { cookiesRegister, typeCookieEmail, typeCookiePageId, typeCookiePassw } from 'fixedData';

// Delete
export function Reg_Delete(userId, id, origin, type, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append("id", id);
    data.append('userId', userId);
    data.append('type_page', origin);
    data.append('edit_data', type);
    data.append('typePage', "");

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle(origin, response.data[origin]);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Edit Config
export function Reg_EditConfig(idiomaEn, idiomaEs, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('type_page', 'dataConfig');
    data.append('edit_data', 'edit_or_register_data');

    data.append('idiomaEn', idiomaEn);
    data.append('idiomaEs', idiomaEs);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle('dataConfig', response.data.dataConfig);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Change position
export function Reg_AltPositionMenu(id, order, type, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_data', 'alt_position');
    data.append('type_page', 'dataConfig');
    data.append('typePage', "");

    data.append('id', id);
    data.append('order', order);
    data.append('type', type);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        
        SetListDataSingle('dataConfig', response.data.dataConfig);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Alt status menu
export function Reg_StatusMenu(id, status, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_data', 'alt_status_menu');
    data.append('type_page', 'dataConfig');
    data.append('typePage', "");

    data.append('id', id);
    data.append('status', status);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);

        SetListDataSingle('dataConfig', response.data.dataConfig);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Edit or register data user
export function Reg_UserDash(userId, idPage, name, email, pass, file, nivel, note, addPage, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'accessDash');
    data.append('edit_data', 'edit_profile');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);
    data.append('nivel', nivel);
    data.append('note', note);

    addPage.forEach((element, index) => {
        data.append('page_value[]', element.value);
        data.append('page_name[]', element.label);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('accessDash', response.data.accessDash);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }

        if(userId == idPage){
            const newData = response.data.accessDash.find(item => item.id == userId);
            cookies.set(typeCookieEmail, email, '/', cookiesRegister);
            if(pass !=''){
                cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
            }

            SetUserData('id', newData['id']);
            SetUserData('file', newData['file']);
            SetUserData('name', newData['name']);
            SetUserData('email', newData['email']);
            SetUserData('access', newData['access']);
            SetUserData('page', newData['page']);
        }

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Edit user logado
export function Reg_EditUser(id, name, email, pass, file, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'accessDash');
    data.append('edit_data', 'edit_user');

    data.append('id', id);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('accessDash', response.data.accessDash);
        const newData = response.data.accessDash.find(item => item.id == id);
        cookies.set(typeCookieEmail, email, '/', cookiesRegister);
        if(pass !=''){
            cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
        }
        SetUserData('id', newData['id']);
        SetUserData('file', newData['file']);
        SetUserData('name', newData['name']);
        SetUserData('email', newData['email']);

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Change position
export function Reg_AltPosition(origin, id, order, type, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_data', 'alt_position');
    data.append('type_page', 'site');
    data.append('typePage', "");

    data.append('id', id);
    data.append('origin', origin);
    data.append('order', order);
    data.append('type', type);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle(origin, response.data[origin]);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Register data
export function Reg_RegisterData(origin, userId, idPage, title, color, typePage, enContent, esContent, CallbackSuccess, CallbackError){
    const data    = new FormData();
    const cookies = new Cookies();
    data.append('edit_data', 'edit_or_register_data');
    data.append('type_page', 'site');

    data.append('origin', origin);
    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('title', title);
    data.append('color', color);
    data.append('typePage', typePage);

    switch (typePage) {
        case "home":
                // en
                if(enContent){
                    data.append('en_title', enContent.en_title);
                    data.append('en_text', enContent.en_text);
                    data.append('en_file', enContent.en_file);
                    data.append('en_type_file', enContent.en_type_file);
                }
                // es
                if(esContent){
                    data.append('es_title', esContent.es_title);
                    data.append('es_text', esContent.es_text);
                    data.append('es_file', esContent.es_file);
                    data.append('es_type_file', esContent.es_type_file);
                }
            break;

        case "text": case "text_link":
                // en
                if(enContent){
                    data.append('en_subtitle', enContent.en_subtitle);
                    data.append('en_title', enContent.en_title);
                    data.append('en_text', enContent.en_text);
                    data.append('en_link', enContent.en_link);
                    data.append('en_bg_title', enContent.en_bg_title);
                }
                // es
                if(esContent){
                    data.append('es_subtitle', enContent.es_subtitle);
                    data.append('es_title', esContent.es_title);
                    data.append('es_text', esContent.es_text);
                    data.append('es_link', enContent.es_link);
                    data.append('es_bg_title', enContent.es_bg_title);
                }
            break;

        case "video":
                // en
                if(enContent){
                    data.append('en_subtitle', enContent.en_subtitle);
                    data.append('en_title', enContent.en_title);
                    data.append('en_file', enContent.en_file);
                    data.append('en_type_file', enContent.en_type_file);
                }
                // es
                if(esContent){
                    data.append('es_subtitle', enContent.es_subtitle);
                    data.append('es_title', esContent.es_title);
                    data.append('es_file', esContent.es_file);
                    data.append('es_type_file', esContent.es_type_file);
                }
            break;

        case "our_services":
                // en
                data.append('en_subtitle', enContent.en_subtitle); 
                data.append('en_title', enContent.en_title); 
                data.append('en_text', enContent.en_text);
                if(enContent.en_contents){
                    enContent.en_contents.forEach((elem, index)=> {
                        data.append('en_contents_id[]', elem.id);
                        data.append('en_contents_title[]', elem.title);
                        data.append('en_contents_text[]', elem.text);
                    });
                }
                // es
                data.append('es_subtitle', esContent.es_subtitle);
                data.append('es_title', esContent.es_title);
                data.append('es_text', esContent.es_text);
                if(esContent.es_contents){
                    esContent.es_contents.forEach((elem, index)=> {
                        data.append('es_contents_id[]', elem.id);
                        data.append('es_contents_title[]', elem.title);
                        data.append('es_contents_text[]', elem.text);
                    });
                }
            break;

        case "logo_clients":
                // en
                if(enContent.en_contents){
                    enContent.en_contents.forEach((elem, index)=> {
                        data.append('en_contents_id[]', elem.id);
                        data.append('en_contents_title[]', elem.title);
                        data.append('en_contents_file_' + index, elem.file);
                    });
                }
                // es
                if(esContent.es_contents){
                    esContent.es_contents.forEach((elem, index)=> {
                        data.append('es_contents_id[]', elem.id);
                        data.append('es_contents_title[]', elem.title);
                        data.append('es_contents_file_' + index, elem.file);
                    });
                }
            break;

        case "gallery_model_1": case "gallery_model_2": case "gallery_model_3":
                // en
                if(enContent.en_contents){
                    enContent.en_contents.forEach((elem, index)=> {
                        data.append('en_contents_id[]', elem.id);
                        data.append('en_contents_type_portfolio[]', elem.type_portfolio);
                        data.append('en_contents_id_portfolio[]', elem.id_portfolio);
                        data.append('en_contents_name_portfolio[]', elem.name_portfolio);
                        data.append('en_contents_title[]', elem.title);
                        data.append('en_contents_text[]', elem.text);
                        data.append('en_contents_client[]', elem.client);
                        data.append('en_contents_type[]', elem.type);
                        data.append('en_contents_year[]', elem.year);
                        data.append('en_contents_office[]', elem.office);
                        data.append('en_contents_name[]', elem.name);
                        data.append('en_contents_file_' + index, elem.file);
                    });
                }
                // es
                if(esContent.es_contents){
                    esContent.es_contents.forEach((elem, index)=> {
                        data.append('es_contents_id[]', elem.id);
                        data.append('es_contents_type_portfolio[]', elem.type_portfolio);
                        data.append('es_contents_id_portfolio[]', elem.id_portfolio);
                        data.append('es_contents_name_portfolio[]', elem.name_portfolio);
                        data.append('es_contents_title[]', elem.title);
                        data.append('es_contents_text[]', elem.text);
                        data.append('es_contents_client[]', elem.client);
                        data.append('es_contents_type[]', elem.type);
                        data.append('es_contents_year[]', elem.year);
                        data.append('es_contents_office[]', elem.office);
                        data.append('es_contents_name[]', elem.name);
                        data.append('es_contents_file_' + index, elem.file);
                    });
                }
            break;
    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle(origin, response.data[origin]);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Register portfolio
export function Reg_RegisterPortfolio(userId, idPage, enShowGallery, enGalleryTitle, enGalleryText, enGalleryFile, enProject, enSubtitle, enType, enColorPage, enYear, enClient, enText, enTypeCover, enContents, enDatasheet, enFile, enPositionX, enPositionY, enWidth, enHeight, enTypeCrop, enShowWidth, enWidthReal, esShowGallery, esGalleryTitle, esGalleryText, esGalleryFile, esProject, esSubtitle, esType, esColorPage, esYear, esClient, esText, esTypeCover, esContents, esDatasheet, esFile, esPositionX, esPositionY, esWidth, esHeight, esTypeCrop, esShowWidth, esWidthReal, CallbackSuccess, CallbackError){

    const data    = new FormData();
    const cookies = new Cookies();
    data.append('edit_data', 'edit_or_register_data');
    data.append('type_page', 'portfolio');
    data.append('userId', userId);
    data.append('idPage', idPage);

    // en
    data.append('en_show_gallery', enShowGallery);
    data.append('en_gallery_title', enGalleryTitle);
    data.append('en_gallery_text', enGalleryText);
    data.append('en_gallery_file', enGalleryFile);

    data.append('en_project', enProject);
    data.append('en_subtitle', enSubtitle);
    data.append('en_type', enType);
    data.append('en_color', enColorPage);
    data.append('en_year', enYear);
    data.append('en_client', enClient);
    data.append('en_text', enText);
    data.append('en_datasheet', enDatasheet);
    data.append('en_type_cover', enTypeCover);
    data.append('en_file', enFile);

    data.append('en_position_x', enPositionX);
    data.append('en_position_y', enPositionY);
    data.append('en_width', enWidth);
    data.append('en_height', enHeight);
    data.append('en_type_crop', enTypeCrop);
    data.append('en_show_width', enShowWidth);
    data.append('en_width_real', enWidthReal);
    
    if(enContents){
        enContents.forEach((elent_0, index_0) => {
            data.append('en_data_id[]', elent_0.id);
            data.append('en_data_type[]', elent_0.type);
            data.append('en_data_container[]', elent_0.container);
            data.append('en_data_width[]', elent_0.width);
            data.append('en_data_height[]', elent_0.height);

            if(elent_0.contents){
                elent_0.contents.forEach((elent_1, index_1) => {
                    data.append('en_index_0[]', index_0);
                    data.append('en_index_1[]', index_1);
                    data.append('en_contents_id_' + index_0 + '_' + index_1, elent_1.id);
                    data.append('en_contents_id_content_' + index_0 + '_' + index_1, elent_1.id_content);
                    data.append('en_contents_type_' + index_0 + '_' + index_1, elent_1.type);
                    data.append('en_contents_width_' + index_0 + '_' + index_1, elent_1.width);
                    data.append('en_contents_height_' + index_0 + '_' + index_1, elent_1.height);

                    data.append('en_contents_type_video_' + index_0 + '_' + index_1, elent_1.type_video);
                    data.append('en_contents_link_' + index_0 + '_' + index_1, elent_1.link);
                    data.append('en_contents_subtitle_' + index_0 + '_' + index_1, elent_1.subtitle);
                    data.append('en_contents_title_' + index_0 + '_' + index_1, elent_1.title);
                    data.append('en_contents_file_' + index_0 + '_' + index_1, elent_1.file);
                    data.append('en_contents_name_' + index_0 + '_' + index_1, elent_1.name);
                    data.append('en_contents_text_' + index_0 + '_' + index_1, elent_1.text);
                    data.append('en_contents_text_1_' + index_0 + '_' + index_1, elent_1.text_1);                    

                    data.append('en_contents_data_type_crop_' + index_0 + '_' + index_1, elent_1.typeCrop);
                    data.append('en_contents_data_positionX_' + index_0 + '_' + index_1, elent_1.positionX);
                    data.append('en_contents_data_positionY_' + index_0 + '_' + index_1, elent_1.positionY);
                    data.append('en_contents_data_width_' + index_0 + '_' + index_1, elent_1.width);
                    data.append('en_contents_data_height_' + index_0 + '_' + index_1, elent_1.height);
                    data.append('en_contents_data_showWidth_' + index_0 + '_' + index_1, elent_1.showWidth);
                    data.append('en_contents_data_widthReal_' + index_0 + '_' + index_1, elent_1.widthReal);
                    
                    data.append('en_contents_data_model_gallery_' + index_0 + '_' + index_1, elent_1.model_gallery);
                    if(elent_1.gallery){
                        elent_1.gallery.forEach((elent_2, index_2) => {
                            data.append('en_gallery_index_0[]', index_0);
                            data.append('en_gallery_index_1[]', index_1);
                            data.append('en_gallery_index_2[]', index_2);

                            data.append('en_gallery_id_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.id);
                            data.append('en_gallery_title_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.title);
                            data.append('en_gallery_text_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.text);
                            data.append('en_gallery_file_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.file);
                        });
                    }
                });
            }
        });
    }

    // es
    data.append('es_show_gallery', esShowGallery);
    data.append('es_gallery_title', esGalleryTitle);
    data.append('es_gallery_text', esGalleryText);
    data.append('es_gallery_file', esGalleryFile);
    
    data.append('es_project', esProject);
    data.append('es_subtitle', esSubtitle);
    data.append('es_type', esType);
    data.append('es_color', esColorPage);
    data.append('es_year', esYear);
    data.append('es_client', esClient);
    data.append('es_text', esText);
    data.append('es_datasheet', esDatasheet);
    data.append('es_file', esFile);
    data.append('es_type_cover', esTypeCover);

    data.append('es_position_x', esPositionX);
    data.append('es_position_y', esPositionY);
    data.append('es_width', esWidth);
    data.append('es_height', esHeight);
    data.append('es_type_crop', esTypeCrop);
    data.append('es_show_width', esShowWidth);
    data.append('es_width_real', esWidthReal);

    if(esContents){
        esContents.forEach((elent_0, index_0) => {
            data.append('es_data_id[]', elent_0.id);
            data.append('es_data_type[]', elent_0.type);
            data.append('es_data_container[]', elent_0.container);
            data.append('es_data_width[]', elent_0.width);
            data.append('es_data_height[]', elent_0.height);

            if(elent_0.contents){
                elent_0.contents.forEach((elent_1, index_1) => {
                    data.append('es_index_0[]', index_0);
                    data.append('es_index_1[]', index_1);
                    data.append('es_contents_id_' + index_0 + '_' + index_1, elent_1.id);
                    data.append('es_contents_id_content_' + index_0 + '_' + index_1, elent_1.id_content);
                    data.append('es_contents_type_' + index_0 + '_' + index_1, elent_1.type);
                    data.append('es_contents_width_' + index_0 + '_' + index_1, elent_1.width);
                    data.append('es_contents_height_' + index_0 + '_' + index_1, elent_1.height);

                    data.append('es_contents_type_video_' + index_0 + '_' + index_1, elent_1.type_video);
                    data.append('es_contents_link_' + index_0 + '_' + index_1, elent_1.link);
                    data.append('es_contents_subtitle_' + index_0 + '_' + index_1, elent_1.subtitle);
                    data.append('es_contents_title_' + index_0 + '_' + index_1, elent_1.title);
                    data.append('es_contents_file_' + index_0 + '_' + index_1, elent_1.file);
                    data.append('es_contents_name_' + index_0 + '_' + index_1, elent_1.name);
                    data.append('es_contents_text_' + index_0 + '_' + index_1, elent_1.text);
                    data.append('es_contents_text_1_' + index_0 + '_' + index_1, elent_1.text_1);                    

                    data.append('es_contents_data_type_crop_' + index_0 + '_' + index_1, elent_1.typeCrop);
                    data.append('es_contents_data_positionX_' + index_0 + '_' + index_1, elent_1.positionX);
                    data.append('es_contents_data_positionY_' + index_0 + '_' + index_1, elent_1.positionY);
                    data.append('es_contents_data_width_' + index_0 + '_' + index_1, elent_1.width);
                    data.append('es_contents_data_height_' + index_0 + '_' + index_1, elent_1.height);
                    data.append('es_contents_data_showWidth_' + index_0 + '_' + index_1, elent_1.showWidth);
                    data.append('es_contents_data_widthReal_' + index_0 + '_' + index_1, elent_1.widthReal);
                    
                    data.append('es_contents_data_model_gallery_' + index_0 + '_' + index_1, elent_1.model_gallery);
                    if(elent_1.gallery){
                        elent_1.gallery.forEach((elent_2, index_2) => {
                            data.append('es_gallery_index_0[]', index_0);
                            data.append('es_gallery_index_1[]', index_1);
                            data.append('es_gallery_index_2[]', index_2);

                            data.append('es_gallery_id_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.id);
                            data.append('es_gallery_title_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.title);
                            data.append('es_gallery_text_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.text);
                            data.append('es_gallery_file_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.file);
                        });
                    }
                });
            }
        });
    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('portfolio', response.data.portfolio);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Copy generator 
export function Reg_CopyGenerator(origin, userId, id, idioma, copyIdioma, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', origin);
    data.append('edit_data', "copy_generator");

    data.append("id", id);
    data.append('userId', userId);
    data.append('idioma', idioma);
    data.append('copyIdioma', copyIdioma);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle(origin, response.data[origin]);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Register service
export function Reg_RegisterService(userId, idPage, enShowGallery, enGalleryTitle, enGalleryText, enGalleryFile, enProject, enSubtitle, enType, enColorPage, enText, enTypeCover, enContents, enDatasheet, enFile, enPositionX, enPositionY, enWidth, enHeight, enTypeCrop, enShowWidth, enWidthReal, esShowGallery, esGalleryTitle, esGalleryText, esGalleryFile, esProject, esSubtitle, esType, esColorPage, esText, esTypeCover, esContents, esDatasheet, esFile, esPositionX, esPositionY, esWidth, esHeight, esTypeCrop, esShowWidth, esWidthReal, CallbackSuccess, CallbackError){

    const data    = new FormData();
    const cookies = new Cookies();
    data.append('edit_data', 'edit_or_register_data');
    data.append('type_page', 'service');
    data.append('userId', userId);
    data.append('idPage', idPage);

    // en
    data.append('en_show_gallery', enShowGallery);
    data.append('en_gallery_title', enGalleryTitle);
    data.append('en_gallery_text', enGalleryText);
    data.append('en_gallery_file', enGalleryFile);

    data.append('en_project', enProject);
    data.append('en_subtitle', enSubtitle);
    data.append('en_type', enType);
    data.append('en_color', enColorPage);
    data.append('en_text', enText);
    data.append('en_datasheet', enDatasheet);
    data.append('en_type_cover', enTypeCover);
    data.append('en_file', enFile);

    data.append('en_position_x', enPositionX);
    data.append('en_position_y', enPositionY);
    data.append('en_width', enWidth);
    data.append('en_height', enHeight);
    data.append('en_type_crop', enTypeCrop);
    data.append('en_show_width', enShowWidth);
    data.append('en_width_real', enWidthReal);
    
    if(enContents){
        enContents.forEach((elent_0, index_0) => {
            data.append('en_data_id[]', elent_0.id);
            data.append('en_data_type[]', elent_0.type);
            data.append('en_data_container[]', elent_0.container);
            data.append('en_data_width[]', elent_0.width);
            data.append('en_data_height[]', elent_0.height);

            if(elent_0.contents){
                console.log(elent_0.contents);
                elent_0.contents.forEach((elent_1, index_1) => {
                    data.append('en_index_0[]', index_0);
                    data.append('en_index_1[]', index_1);
                    data.append('en_contents_id_' + index_0 + '_' + index_1, elent_1.id);
                    data.append('en_contents_id_content_' + index_0 + '_' + index_1, elent_1.id_content);
                    data.append('en_contents_type_' + index_0 + '_' + index_1, elent_1.type);
                    data.append('en_contents_width_' + index_0 + '_' + index_1, elent_1.width);
                    data.append('en_contents_height_' + index_0 + '_' + index_1, elent_1.height);

                    data.append('en_contents_type_video_' + index_0 + '_' + index_1, elent_1.type_video);
                    data.append('en_contents_link_' + index_0 + '_' + index_1, elent_1.link);
                    data.append('en_contents_subtitle_' + index_0 + '_' + index_1, elent_1.subtitle);
                    data.append('en_contents_title_' + index_0 + '_' + index_1, elent_1.title);
                    data.append('en_contents_file_' + index_0 + '_' + index_1, elent_1.file);
                    data.append('en_contents_name_' + index_0 + '_' + index_1, elent_1.name);
                    data.append('en_contents_text_' + index_0 + '_' + index_1, elent_1.text);
                    data.append('en_contents_text_1_' + index_0 + '_' + index_1, elent_1.text_1);
                    

                    data.append('en_contents_data_type_crop_' + index_0 + '_' + index_1, elent_1.typeCrop);
                    data.append('en_contents_data_positionX_' + index_0 + '_' + index_1, elent_1.positionX);
                    data.append('en_contents_data_positionY_' + index_0 + '_' + index_1, elent_1.positionY);
                    data.append('en_contents_data_width_' + index_0 + '_' + index_1, elent_1.width);
                    data.append('en_contents_data_height_' + index_0 + '_' + index_1, elent_1.height);
                    data.append('en_contents_data_showWidth_' + index_0 + '_' + index_1, elent_1.showWidth);
                    data.append('en_contents_data_widthReal_' + index_0 + '_' + index_1, elent_1.widthReal);
                    
                    data.append('en_contents_data_model_gallery_' + index_0 + '_' + index_1, elent_1.model_gallery);
                    if(elent_1.gallery){
                        elent_1.gallery.forEach((elent_2, index_2) => {
                            data.append('en_gallery_index_0[]', index_0);
                            data.append('en_gallery_index_1[]', index_1);
                            data.append('en_gallery_index_2[]', index_2);

                            data.append('en_gallery_id_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.id);
                            data.append('en_gallery_title_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.title);
                            data.append('en_gallery_text_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.text);
                            data.append('en_gallery_file_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.file);
                        });
                    }
                });
            }
        });
    }

    // es
    data.append('es_show_gallery', esShowGallery);
    data.append('es_gallery_title', esGalleryTitle);
    data.append('es_gallery_text', esGalleryText);
    data.append('es_gallery_file', esGalleryFile);

    data.append('es_project', esProject);
    data.append('es_subtitle', esSubtitle);
    data.append('es_type', esType);
    data.append('es_color', esColorPage);
    data.append('es_text', esText);
    data.append('es_datasheet', esDatasheet);
    data.append('es_file', esFile);
    data.append('es_type_cover', esTypeCover);

    data.append('es_position_x', esPositionX);
    data.append('es_position_y', esPositionY);
    data.append('es_width', esWidth);
    data.append('es_height', esHeight);
    data.append('es_type_crop', esTypeCrop);
    data.append('es_show_width', esShowWidth);
    data.append('es_width_real', esWidthReal);

    if(esContents){
        esContents.forEach((elent_0, index_0) => {
            data.append('es_data_id[]', elent_0.id);
            data.append('es_data_type[]', elent_0.type);
            data.append('es_data_container[]', elent_0.container);
            data.append('es_data_width[]', elent_0.width);
            data.append('es_data_height[]', elent_0.height);

            if(elent_0.contents){
                elent_0.contents.forEach((elent_1, index_1) => {
                    data.append('es_index_0[]', index_0);
                    data.append('es_index_1[]', index_1);
                    data.append('es_contents_id_' + index_0 + '_' + index_1, elent_1.id);
                    data.append('es_contents_id_content_' + index_0 + '_' + index_1, elent_1.id_content);
                    data.append('es_contents_type_' + index_0 + '_' + index_1, elent_1.type);
                    data.append('es_contents_width_' + index_0 + '_' + index_1, elent_1.width);
                    data.append('es_contents_height_' + index_0 + '_' + index_1, elent_1.height);

                    data.append('es_contents_type_video_' + index_0 + '_' + index_1, elent_1.type_video);
                    data.append('es_contents_link_' + index_0 + '_' + index_1, elent_1.link);
                    data.append('es_contents_subtitle_' + index_0 + '_' + index_1, elent_1.subtitle);
                    data.append('es_contents_title_' + index_0 + '_' + index_1, elent_1.title);
                    data.append('es_contents_file_' + index_0 + '_' + index_1, elent_1.file);
                    data.append('es_contents_name_' + index_0 + '_' + index_1, elent_1.name);
                    data.append('es_contents_text_' + index_0 + '_' + index_1, elent_1.text);
                    data.append('es_contents_text_1_' + index_0 + '_' + index_1, elent_1.text_1);
                    

                    data.append('es_contents_data_type_crop_' + index_0 + '_' + index_1, elent_1.typeCrop);
                    data.append('es_contents_data_positionX_' + index_0 + '_' + index_1, elent_1.positionX);
                    data.append('es_contents_data_positionY_' + index_0 + '_' + index_1, elent_1.positionY);
                    data.append('es_contents_data_width_' + index_0 + '_' + index_1, elent_1.width);
                    data.append('es_contents_data_height_' + index_0 + '_' + index_1, elent_1.height);
                    data.append('es_contents_data_showWidth_' + index_0 + '_' + index_1, elent_1.showWidth);
                    data.append('es_contents_data_widthReal_' + index_0 + '_' + index_1, elent_1.widthReal);
                    
                    data.append('es_contents_data_model_gallery_' + index_0 + '_' + index_1, elent_1.model_gallery);
                    if(elent_1.gallery){
                        elent_1.gallery.forEach((elent_2, index_2) => {
                            data.append('es_gallery_index_0[]', index_0);
                            data.append('es_gallery_index_1[]', index_1);
                            data.append('es_gallery_index_2[]', index_2);

                            data.append('es_gallery_id_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.id);
                            data.append('es_gallery_title_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.title);
                            data.append('es_gallery_text_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.text);
                            data.append('es_gallery_file_' + index_0 + '_' + index_1 + '_' + index_2, elent_2.file);
                        });
                    }
                });
            }
        });
    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);

        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('service', response.data.service);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Copy Data 
export function Reg_CopyData(typeCopy, userId, id, name, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', typeCopy);
    data.append('edit_data', "copy_data");

    data.append('userId', userId);
    data.append("id", id);
    data.append('name', name);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle('portfolio', response.data['portfolio']);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}