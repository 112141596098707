import { useState, useEffect } from "react";

import './Gallery.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgDelete, SvgImg } from "components/SvgFile";

export default function Model_Gallery(props){

    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ listService, setListService ] = useState(GetDataPage('service'));

    // en
    const [ enContents, setEnContents ] = useState(InitialData('en_contents', 'en_contents'));

    // es
    const [ esContents, setEsContents ] = useState(InitialData('es_contents', 'es_contents'));

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);
        RegisterDataPage('service', setListService);
    }, []);

    useEffect(()=>{
        // en
        setEnContents(InitialData('en_contents', 'en_contents'));

        // es
        setEsContents(InitialData('es_contents', 'es_contents'));
    }, [dataPage, idPage]);

    function InitialData(key, type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[key][type];
        }
        if(type == 'en_contents' || type == 'es_contents'){
            return [];
        }
        return "";
    }

    function ListService(){
        const options = []
        listService.map((key, index)=>{
            let labelOpt = key.en_client + ' - ' + key.en_project;
            options.push({
                "value": key.id, "label": labelOpt
            });
        })
        return options;
    }

    function HandleDataContents(type, index, value){
        // en
        if(idioma == 'en'){
            const newData = [...enContents];
            if(type == 'img'){
                if(value){
                    newData[index]['file']   = value;
                    newData[index]['status'] = true;
                }else {
                    newData[index]['file']   = "";
                    newData[index]['status'] = false;
                }
            }else if(type == 'portfolio'){
                newData[index]['id_portfolio'] = value.value;
                newData[index]['name_portfolio'] = value.label;
                
            }else {
                newData[index][type] = value;
            }
            
            props.RegisterData('en', { "en_contents": newData });
            setEnContents(newData);
        }
        // es
        if(idioma == 'es'){
            const newData = [...esContents];
            if(type == 'img'){
                if(value){
                    newData[index]['file']   = value;
                    newData[index]['status'] = true;
                }else {
                    newData[index]['file']   = "";
                    newData[index]['status'] = false;
                }
            }else if(type == 'portfolio'){
                newData[index]['id_portfolio'] = value.value;
                newData[index]['name_portfolio'] = value.label;
                
            }else {
                newData[index][type] = value;
            }
            
            props.RegisterData('es', { "es_contents": newData });
            setEsContents(newData);
        }
    }

    function NewContent(){
        if(idioma == 'en'){
            setEnContents([...enContents, { "id": 0, "type_portfolio": props.type, "id_portfolio": 0, "name_portfolio": "Selecione uma opção", "title": "", "text": "", "client": "", "type": "", "year": "", "file": "", "status": false, "office": "", "name": "" }]);  
        }

        if(idioma == 'es'){
            setEsContents([...esContents, { "id": 0, "type_portfolio": props.type, "id_portfolio": 0, "name_portfolio": "Selecione uma opção", "title": "", "text": "", "client": "", "type": "", "year": "", "file": "", "status": false, "office": "", "name": "" }]);              
        }
    }

    function DeleteData(id, index, value){
        if(id == 0){            
            if(idioma == 'en'){
                const newData = [...enContents];
                newData.splice(index, 1);
                setEnContents(newData);       
            }

            if(idioma == 'es'){
                const newData = [...esContents];
                newData.splice(index, 1);
                setEsContents(newData);          
            }
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "site", "type": "delete_contents", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="Model_Gallery">
            <div className="list_input_data">
                <div className="div_input space_div show_data_text">
                    {
                        idioma == 'en' ?
                            <>
                                <div className="list_input_data">
                                    <div className="div_input space_div show_data_text">
                                        <div className="div_subtitle_add">
                                            <div className="">
                                                Gallery
                                            </div>
                                            <div className="new_data_page" onClick={ ()=>{ NewContent() } }>
                                                <span className="icons_menu">+</span> file
                                            </div>                                  
                                        </div>
                                        {
                                            enContents.map((key, index)=>{
                                                return(           
                                                    <div className="list_contents" key={ index } style={ { flexDirection: 'column', gap: "0px" } }>
                                                        <div className="list_input_data">
                                                            <div className="div_input space_div">
                                                                <Select className="select_signature" defaultOptions options={ ListService() } onChange={ (e)=> { HandleDataContents('portfolio', index, e) } } value={ { "value": key.id_portfolio, "label": key.name_portfolio } } required />
                                                                <span className="name_input">Services</span>
                                                            </div>
                                                            <div className="div_input show_icons" onClick={ ()=>{ DeleteData(key.id, index, key.name) } } style={ { display: 'flex' } }>
                                                                <SvgDelete color="#f00000" className="icons" />
                                                            </div>
                                                        </div>
                                                        {
                                                            props.type == 3 ?
                                                            <>      
                                                                <div className="list_input_data">
                                                                    <div className="div_input space_div">
                                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('title', index, e.target.value) } } value={ key.title } />
                                                                        <span className="name_input">Title</span>
                                                                    </div>
                                                                    <div className="div_input space_div">
                                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('name', index, e.target.value) } } value={ key.name } />
                                                                        <span className="name_input">Name</span>
                                                                    </div>
                                                                    <div className="div_input space_div">
                                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('office', index, e.target.value) } } value={ key.office } />
                                                                        <span className="name_input">Office</span>
                                                                    </div>
                                                                    <div className="div_input space_top">
                                                                        <div className={ key.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                                            <label>
                                                                                { key.status == true ? "Image added" : "Add image" }
                                                                                <input type="file" onChange={ (e)=>{ HandleDataContents('img', index, e.target.files[0]); } } accept="image/*" />
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        key.file  ?
                                                                            key.status == true ?
                                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                                            </div> :
                                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg', "cover/" + key.file) } }>
                                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                                            </div>
                                                                        : null
                                                                    }
                                                                </div>
                                                                <div className="list_input_data">
                                                                    <div className="div_input space_div show_data_text">
                                                                        <div className="show_title">Text</div>
                                                                        <JoditEditor className="height_textarea" config={ config } value={ key.text !='' ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, newContent) } />
                                                                    </div>
                                                                </div> 
                                                            </>
                                                            :
                                                            <>
                                                                <div className="list_input_data">
                                                                    <div className="div_input space_div">
                                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('title', index, e.target.value) } } value={ key.title } />
                                                                        <span className="name_input">Title</span>
                                                                    </div>
                                                                    {
                                                                        props.type == 1 ?
                                                                        <>
                                                                            <div className="div_input space_top">
                                                                                <div className={ key.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                                                    <label>
                                                                                        { key.status == true ? "Image added" : "Add image" }
                                                                                        <input type="file" onChange={ (e)=>{ HandleDataContents('img', index, e.target.files[0]); } } accept="image/*" />
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                key.file  ?
                                                                                    key.status == true ?
                                                                                    <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                                                        <SvgImg color="#324d6b" className="icons_img"/>
                                                                                    </div> :
                                                                                    <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg', "cover/" + key.file) } }>
                                                                                        <SvgImg color="#324d6b" className="icons_img"/>
                                                                                    </div>
                                                                                : null
                                                                            }
                                                                        </> : null
                                                                    }
                                                                </div>
                                                                <div className="list_input_data">
                                                                    <div className="div_input space_div show_data_text">
                                                                        <div className="show_title">Text</div>
                                                                        <JoditEditor className="height_textarea" config={ config } value={ key.text !='' ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, newContent) } />
                                                                    </div>
                                                                </div> 
                                                            </>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </> :
                            <>
                                <div className="list_input_data">
                                    <div className="div_input space_div show_data_text">
                                        <div className="div_subtitle_add">
                                            <div className="">
                                                Galería
                                            </div>
                                            <div className="new_data_page" onClick={ ()=>{ NewContent() } }>
                                                <span className="icons_menu">+</span> archivo
                                            </div>                                  
                                        </div>
                                        {
                                            esContents.map((key, index)=>{
                                                return(           
                                                    <div className="list_contents" key={ index } style={ { flexDirection: 'column', gap: "0px" } }>
                                                        <div className="list_input_data">
                                                            <div className="div_input space_div">
                                                                <Select className="select_signature" defaultOptions options={ ListService() } onChange={ (e)=> { HandleDataContents('portfolio', index, e) } } value={ { "value": key.id_portfolio, "label": key.name_portfolio } } required />
                                                                <span className="name_input">Servicios</span>
                                                            </div>
                                                            <div className="div_input show_icons" onClick={ ()=>{ DeleteData(key.id, index, key.name) } } style={ { display: 'flex' } }>
                                                                <SvgDelete color="#f00000" className="icons" />
                                                            </div>
                                                        </div>
                                                        {
                                                            props.type == 3 ?
                                                            <>      
                                                                <div className="list_input_data">
                                                                    <div className="div_input space_div">
                                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('title', index, e.target.value) } } value={ key.title } />
                                                                        <span className="name_input">Título</span>
                                                                    </div>
                                                                    <div className="div_input space_div">
                                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('name', index, e.target.value) } } value={ key.name } />
                                                                        <span className="name_input">Nombre</span>
                                                                    </div>
                                                                    <div className="div_input space_div">
                                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('office', index, e.target.value) } } value={ key.office } />
                                                                        <span className="name_input">Oficina</span>
                                                                    </div>
                                                                    <div className="div_input space_top">
                                                                        <div className={ key.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                                            <label>
                                                                                { key.status == true ? "Imagen agregada" : "Añadir imagen" }
                                                                                <input type="file" onChange={ (e)=>{ HandleDataContents('img', index, e.target.files[0]); } } accept="image/*" />
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        key.file  ?
                                                                            key.status == true ?
                                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                                            </div> :
                                                                            <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg', "cover/" + key.file) } }>
                                                                                <SvgImg color="#324d6b" className="icons_img"/>
                                                                            </div>
                                                                        : null
                                                                    }
                                                                </div>
                                                                <div className="list_input_data">
                                                                    <div className="div_input space_div show_data_text">
                                                                        <div className="show_title">Texto</div>
                                                                        <JoditEditor className="height_textarea" config={ config } value={ key.text !='' ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, newContent) } />
                                                                    </div>
                                                                </div> 
                                                            </>
                                                            :
                                                            <>
                                                                <div className="list_input_data">
                                                                    <div className="div_input space_div">
                                                                        <input type="text" onChange={ (e)=>{ HandleDataContents('title', index, e.target.value) } } value={ key.title } />
                                                                        <span className="name_input">Título</span>
                                                                    </div>
                                                                    {
                                                                        props.type == 1 ?
                                                                        <>
                                                                            <div className="div_input space_top">
                                                                                <div className={ key.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                                                    <label>
                                                                                        { key.status == true ? "Imagen agregada" : "Añadir imagen" }
                                                                                        <input type="file" onChange={ (e)=>{ HandleDataContents('img', index, e.target.files[0]); } } accept="image/*" />
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                key.file  ?
                                                                                    key.status == true ?
                                                                                    <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                                                        <SvgImg color="#324d6b" className="icons_img"/>
                                                                                    </div> :
                                                                                    <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg', "cover/" + key.file) } }>
                                                                                        <SvgImg color="#324d6b" className="icons_img"/>
                                                                                    </div>
                                                                                : null
                                                                            }
                                                                        </> : null
                                                                    }
                                                                </div>
                                                                <div className="list_input_data">
                                                                    <div className="div_input space_div show_data_text">
                                                                        <div className="show_title">Texto</div>
                                                                        <JoditEditor className="height_textarea" config={ config } value={ key.text !='' ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, newContent) } />
                                                                    </div>
                                                                </div> 
                                                            </>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}