import { useState, useEffect } from "react";

import './Home.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgDelete, SvgImg, SvgVimeo, SvgYoutube } from "components/SvgFile";

export default function Model_Home(props){
    
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    // en
    const [ enTitle, setEnTitle ]       = useState(InitialData('en_contents', 'en_title'));
    const [ enText, setEnText ]         = useState(InitialData('en_contents', 'en_text'));
    const [ enFile, setEnFile ]         = useState(InitialData('en_contents', 'en_file'));
    const [ enStatus, setEnStatus ]     = useState(false);
    const [ enTypeFile, setEnTypeFile ] = useState(InitialData('en_contents', 'en_type_file'));

    // es
    const [ esTitle, setEsTitle ]       = useState(InitialData('es_contents', 'es_title'));
    const [ esText, setEsText ]         = useState(InitialData('es_contents', 'es_text'));
    const [ esFile, setEsFile ]         = useState(InitialData('es_contents', 'es_file'));
    const [ esStatus, setEsStatus ]     = useState(false);
    const [ esTypeFile, setEsTypeFile ] = useState(InitialData('es_contents', 'es_type_file'));

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        // en
        setEnTitle(InitialData('en_contents', 'en_title'));
        setEnText(InitialData('en_contents', 'en_text'));
        setEnFile(InitialData('en_contents', 'en_file'));
        setEnStatus(false);
        setEnTypeFile(InitialData('en_contents', 'en_type_file'));

        // es
        setEsTitle(InitialData('es_contents', 'es_title'));
        setEsText(InitialData('es_contents', 'es_text'));  
        setEsFile(InitialData('es_contents', 'es_file'));
        setEsStatus(false);
        setEsTypeFile(InitialData('es_contents', 'es_type_file'));
    }, [dataPage, idPage]);

    function InitialData(key, type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[key][type];
        }
        if(type == 'en_type_file' || type == 'es_type_file'){
            return 'Image'
        }
        return "";
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function HandleData(type, value){
        switch (type) {
            case "enTitle":
                    setEnTitle(value);
                    props.RegisterData('en', { "en_title": value, "en_text": enText, "en_file": enFile, "en_type_file": enTypeFile });
                    props.RegisterData('es', { "es_title": esTitle, "es_text": esText, "es_file": esFile, "es_type_file": esTypeFile });
                break;
            case "enText":
                    setEnText(value);
                    props.RegisterData('en', { "en_title": enTitle, "en_text": value, "en_file": enFile, "en_type_file": enTypeFile });
                    props.RegisterData('es', { "es_title": esTitle, "es_text": esText, "es_file": esFile, "es_type_file": esTypeFile });
                break; 
            case "enFile":
                    if(value){
                        setEnFile(value);
                        setEnStatus(true);
                    }else{
                        setEnFile("");
                        setEnStatus(false);
                    }
                    props.RegisterData('en', { "en_title": enTitle, "en_text": enFile, "en_file": value, "en_type_file": enTypeFile });
                    props.RegisterData('es', { "es_title": esTitle, "es_text": esText, "es_file": esFile, "es_type_file": esTypeFile });
                break; 
            case "enTypeFile":
                    setEnTypeFile(value);
                    props.RegisterData('en', { "en_title": enTitle, "en_text": enFile, "en_file": enFile, "en_type_file": value });
                    props.RegisterData('es', { "es_title": esTitle, "es_text": esText, "es_file": esFile, "es_type_file": esTypeFile });
                break; 

            case "esTitle":
                    setEsTitle(value);
                    props.RegisterData('en', { "en_title": enTitle, "en_text": enText, "en_file": enFile, "en_type_file": enTypeFile });
                    props.RegisterData('es', { "es_title": value, "es_text": esText, "es_file": esFile, "es_type_file": esTypeFile });
                break;
            case "esText":
                    setEsText(value);
                    props.RegisterData('en', { "en_title": enTitle, "en_text": enFile, "en_file": enFile, "en_type_file": enTypeFile });
                    props.RegisterData('es', { "es_title": esTitle, "es_text": value, "es_file": esFile, "es_type_file": esTypeFile });
                break; 
            case "esFile":
                    setEsFile(value);
                    if(value){
                        setEsStatus(true);
                    }else{
                        setEsStatus(false);
                    }
                    props.RegisterData('en', { "en_title": enTitle, "en_text": enFile, "en_file": enFile, "en_type_file": enTypeFile });
                    props.RegisterData('es', { "es_title": esTitle, "es_text": esText, "es_file": value, "es_type_file": esTypeFile });
                break; 
            case "esTypeFile":
                    setEsTypeFile(value);
                    props.RegisterData('en', { "en_title": enTitle, "en_text": enFile, "en_file": enFile, "en_type_file": enTypeFile });
                    props.RegisterData('es', { "es_title": esTitle, "es_text": esText, "es_file": esFile, "es_type_file": value });
                break; 
        }
    }

    return(
        <div className="Model_Home">
            <div className="list_input_data">
                <div className="div_input space_div show_data_text">
                    {                        
                        idioma == 'en' ?
                        <>
                            <div className="list_input_data">
                                <div className="div_input">
                                    <select className="" onChange={ (e)=>{ HandleData('enTypeFile', e.target.value); } } value={ enTypeFile }>
                                        <option value="Image">Imagem</option>
                                        <option value="Vimeo">Vídeo - Vimeo</option>
                                        <option value="Youtube">Vídeo - Youtube</option>
                                    </select>
                                    <span className="name_input">Type</span>
                                </div>
                                {
                                    enTypeFile == 'Image' ?
                                    <>
                                        <div className="div_input space_top">
                                            <div className={ enStatus == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                <label>
                                                    { enStatus == true ? "Product image added" : "Add product image" }
                                                    <input type="file" onChange={ (e)=>{ HandleData('enFile', e.target.files[0]); } } accept="image/*" />
                                                </label>
                                            </div>
                                        </div>
                                        {
                                            enFile ?
                                            enStatus == true ?
                                                <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(enFile)) } }>
                                                    <SvgImg color="#324d6b" className="icons_img"/>
                                                </div> :
                                                <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg', enFile) } }>
                                                    <SvgImg color="#324d6b" className="icons_img"/>
                                                </div>
                                            : null
                                        }
                                    </> :
                                    <>
                                        <div className="div_input">
                                            <input type="text" onChange={ (e)=>{ HandleData('enFile', e.target.value) } } value={ enFile } />
                                            <span className="name_input">Video code</span>
                                        </div>
                                        {
                                            enFile == '' ? null :
                                            <div className="div_input space_top" onClick={ ()=>{ OpenFile(enTypeFile, enFile) } }>
                                                {
                                                    enTypeFile == 'Vimeo' ? 
                                                    <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                        enTypeFile == 'Youtube' ? 
                                                        <SvgYoutube color="#324d6b" className="show_icons_project"/> : null
                                                }
                                                </div>
                                        }
                                    </>
                                }
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ HandleData('enTitle', e.target.value) } } value={ enTitle } />
                                    <div className="name_input">Title</div>
                                </div>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <div className="show_title">Text</div>
                                    <JoditEditor className="height_textarea" config={ config } value={ enText !='' ? enText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('enText', newContent) } />
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="list_input_data">
                                <div className="div_input">
                                    <select className="" onChange={ (e)=>{ HandleData('esTypeFile', e.target.value); } } value={ esTypeFile }>
                                        <option value="Image">Imagem</option>
                                        <option value="Vimeo">Vídeo - Vimeo</option>
                                        <option value="Youtube">Vídeo - Youtube</option>
                                    </select>
                                    <span className="name_input">Tipo</span>
                                </div>
                                {
                                    esTypeFile == 'Image' ?
                                    <>
                                        <div className="div_input space_top">
                                            <div className={ esStatus == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                <label>
                                                    { esStatus == true ? "Imagen del producto añadida" : "Agregar imagen del producto" }
                                                    <input type="file" onChange={ (e)=>{ HandleData('esFile', e.target.files[0]); } } accept="image/*" />
                                                </label>
                                            </div>
                                        </div>
                                        {
                                            esFile ?
                                            esStatus == true ?
                                                <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(esFile)) } }>
                                                    <SvgImg color="#324d6b" className="icons_img"/>
                                                </div> :
                                                <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg', esFile) } }>
                                                    <SvgImg color="#324d6b" className="icons_img"/>
                                                </div>
                                            : null
                                        }
                                    </> :
                                    <>
                                        <div className="div_input">
                                            <input type="text" onChange={ (e)=>{ HandleData('esFile', e.target.value) } } value={ esFile } />
                                            <span className="name_input">Código de vídeo</span>
                                        </div>
                                        {
                                            esFile == '' ? null :
                                            <div className="div_input space_top" onClick={ ()=>{ OpenFile(esTypeFile, esFile) } }>
                                                {
                                                    esTypeFile == 'Vimeo' ? 
                                                    <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                        esTypeFile == 'Youtube' ? 
                                                        <SvgYoutube color="#324d6b" className="show_icons_project"/> : null
                                                }
                                                </div>
                                        }
                                    </>
                                }
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ HandleData('esTitle', e.target.value) } } value={ esTitle } />
                                    <div className="name_input">Título</div>
                                </div>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <div className="show_title">Texto</div>
                                    <JoditEditor className="height_textarea" config={ config } value={ esText !='' ? esText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('esText', newContent) } />
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}