import { useState, useEffect } from "react";

import './LogoClients.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgDelete, SvgImg } from "components/SvgFile";

export default function Model_LogoClients(props){

    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    // en
    const [ enContents, setEnContents ] = useState(InitialData('en_contents', 'en_contents'));

    // es
    const [ esContents, setEsContents ] = useState(InitialData('es_contents', 'es_contents'));

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        // en
        setEnContents(InitialData('en_contents', 'en_contents'));

        // es
        setEsContents(InitialData('es_contents', 'es_contents'));
    }, [dataPage, idPage]);

    function InitialData(key, type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[key][type];
        }
        if(type == 'en_contents' || type == 'es_contents'){
            return [];
        }
        return "";
    }

    function HandleDataContents(type, index, value){
        // en
        if(idioma == 'en'){
            const newData = [...enContents];
            if(type == 'img'){
                if(value){
                    newData[index]['file']   = value;
                    newData[index]['status'] = true;
                }else {
                    newData[index]['file']   = "";
                    newData[index]['status'] = false;
                }
            }else {
                newData[index][type] = value;
            }
            
            props.RegisterData('en', { "en_contents": newData });
            setEnContents(newData);
        }
        // es
        if(idioma == 'es'){
            const newData = [...esContents];
            if(type == 'img'){
                if(value){
                    newData[index]['file']   = value;
                    newData[index]['status'] = true;
                }else {
                    newData[index]['file']   = "";
                    newData[index]['status'] = false;
                }
            }else {
                newData[index][type] = value;
            }
            
            props.RegisterData('es', { "es_contents": newData });
            setEsContents(newData);
        }
    }

    function NewContent(){
        if(idioma == 'en'){
            setEnContents([...enContents, { "id": 0, "title": "", "file": "", "status": false }]);  
        }

        if(idioma == 'es'){
            setEsContents([...esContents, { "id": 0, "title": "", "file": "", "status": false }]);              
        }
    }

    function DeleteData(id, index, value){
        if(id == 0){            
            if(idioma == 'en'){
                const newData = [...enContents];
                newData.splice(index, 1);
                setEnContents(newData);       
            }

            if(idioma == 'es'){
                const newData = [...esContents];
                newData.splice(index, 1);
                setEsContents(newData);          
            }
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "site", "type": "delete_contents", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="Model_OurServices">
            <div className="list_input_data">
                <div className="div_input space_div show_data_text">
                    {
                        idioma == 'en' ?
                            <>
                                <div className="list_input_data">
                                    <div className="div_input space_div show_data_text">
                                        <div className="div_subtitle_add">
                                            <div className="">
                                                Logotipo clients
                                            </div>
                                            <div className="new_data_page" onClick={ ()=>{ NewContent() } }>
                                                <span className="icons_menu">+</span> file
                                            </div>                                  
                                        </div>
                                        {
                                            enContents.map((key, index)=>{
                                                return(           
                                                    <div className="list_contents" key={ index }>
                                                        <div className="div_input space_div">
                                                            <input type="text" onChange={ (e)=>{ HandleDataContents('title', index, e.target.value) } } value={ key.title } />
                                                            <span className="name_input">Title</span>
                                                        </div>
                                                        <div className="div_input space_top">
                                                            <div className={ key.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                                <label>
                                                                    { key.status == true ? "Image added" : "Add image" }
                                                                    <input type="file" onChange={ (e)=>{ HandleDataContents('img', index, e.target.files[0]); } } accept="image/*" />
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {
                                                            key.file  ?
                                                                key.status == true ?
                                                                <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                                    <SvgImg color="#324d6b" className="icons_img"/>
                                                                </div> :
                                                                <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg', "logo_clients/" + key.file) } }>
                                                                    <SvgImg color="#324d6b" className="icons_img"/>
                                                                </div>
                                                            : null
                                                        }
                                                        <div className="div_input show_icons" onClick={ ()=>{ DeleteData(key.id, index, key.name) } } style={ { display: 'flex' } }>
                                                            <SvgDelete color="#f00000" className="icons" />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </> :
                            <>
                                <div className="list_input_data">
                                    <div className="div_input space_div show_data_text">
                                        <div className="div_subtitle_add">
                                            <div className="">
                                                Logotipo clientes
                                            </div>
                                            <div className="new_data_page" onClick={ ()=>{ NewContent() } }>
                                                <span className="icons_menu">+</span> archivo
                                            </div>                                  
                                        </div>
                                        {
                                            esContents.map((key, index)=>{
                                                return(           
                                                    <div className="list_contents" key={ index }>
                                                        <div className="div_input space_div">
                                                            <input type="text" onChange={ (e)=>{ HandleDataContents('title', index, e.target.value) } } value={ key.title } />
                                                            <span className="name_input">Título</span>
                                                        </div>
                                                        <div className="div_input space_top">
                                                            <div className={ key.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                                <label>
                                                                    { key.status == true ? "Imagen añadida" : "Añadir imagen" }
                                                                    <input type="file" onChange={ (e)=>{ HandleDataContents('img', index, e.target.files[0]); } } accept="image/*" />
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {
                                                            key.file  ?
                                                                key.status == true ?
                                                                <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                                    <SvgImg color="#324d6b" className="icons_img"/>
                                                                </div> :
                                                                <div className="div_input space_icons space_top" onClick={ ()=>{ OpenFile('showImg', "logo_clients/" + key.file) } }>
                                                                    <SvgImg color="#324d6b" className="icons_img"/>
                                                                </div>
                                                            : null
                                                        }
                                                        <div className="div_input show_icons" onClick={ ()=>{ DeleteData(key.id, index, key.name) } } style={ { display: 'flex' } }>
                                                            <SvgDelete color="#f00000" className="icons" />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}