import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config, configText } from "fixedData";

import { SvgAddData, SvgDelete, SvgEdit, SvgGoogleDrive, SvgImg, SvgOpt_1, SvgOpt_2, SvgOpt_3, SvgOpt_4, SvgOpt_5, SvgReturnCircle, SvgSave, SvgSetaRight, SvgText, SvgText_Center, SvgText_Right, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Reg_RegisterPortfolio } from "services/RegisterData";

export default function Page_Portfolio_Details(){

    const [ loading, setLoading ]   = useState(false);
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage("portfolio"));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    
    // en
    const [ enShowGallery, setEnShowGallery ]   = useState(InitialData('en_show_gallery'));
    const [ enGalleryTitle, setEnGalleryTitle ] = useState(InitialData('en_gallery_title'));
    const [ enGalleryText, setEnGalleryText ]   = useState(InitialData('en_gallery_text'));
    const [ enGalleryFile, setEnGalleryFile ]   = useState(InitialData('en_gallery_file'));
    const [ enGalleryStatusFile, setEnGalleryStatusFile ] = useState(false);

    const [ enProject, setEnProject ]     = useState(InitialData('en_project'));
    const [ enSubtitle, setEnSubtitle ]   = useState(InitialData('en_subtitle'));
    const [ enType, setEnType ]           = useState(InitialData('en_type'));
    const [ enColorPage, setEnColorPage ] = useState(InitialData('en_color'));
    const [ enYear, setEnYear ]           = useState(InitialData('en_year'));
    const [ enClient, setEnClient ]       = useState(InitialData('en_client'));
    const [ enText, setEnText ]           = useState(InitialData('en_text'));
    const [ enContents, setEnContents ]   = useState(InitialData('en_contents'));
    const [ enDatasheet, setEnDatasheet ] = useState(InitialData('en_datasheet'));
    
    const [ enTypeCover, setEnTypeCover ]   = useState(InitialData('en_type_cover'));
    const [ enFile, setEnFile ]             = useState(InitialData('en_file'));
    const [ enStatusFile, setEnStatusFile ] = useState(false);
    const [ enPositionX, setEnPositionX ]   = useState('');
    const [ enPositionY, setEnPositionY ]   = useState('');
    const [ enWidth, setEnWidth ]           = useState('');
    const [ enHeight, setEnHeight ]         = useState('');
    const [ enTypeCrop, setEnTypeCrop ]     = useState('');
    const [ enShowWidth, setEnShowWidth ]   = useState('');
    const [ enWidthReal, setEnWidthReal ]   = useState('');
    
    // es
    const [ esShowGallery, setEsShowGallery ]   = useState(InitialData('es_show_gallery'));
    const [ esGalleryTitle, setEsGalleryTitle ] = useState(InitialData('es_gallery_title'));
    const [ esGalleryText, setEsGalleryText ]   = useState(InitialData('es_gallery_text'));
    const [ esGalleryFile, setEsGalleryFile ]   = useState(InitialData('es_gallery_file'));
    const [ esGalleryStatusFile, setEsGalleryStatusFile ] = useState(false);

    const [ esProject, setEsProject ]     = useState(InitialData('es_project'));
    const [ esSubtitle, setEsSubtitle ]   = useState(InitialData('es_subtitle'));
    const [ esType, setEsType ]           = useState(InitialData('es_type'));
    const [ esColorPage, setEsColorPage ] = useState(InitialData('es_color'));
    const [ esYear, setEsYear ]           = useState(InitialData('es_year'));
    const [ esClient, setEsClient ]       = useState(InitialData('es_client'));
    const [ esText, setEsText ]           = useState(InitialData('es_text'));
    const [ esContents, setEsContents ]   = useState(InitialData('es_contents'));
    const [ esDatasheet, setEsDatasheet ] = useState(InitialData('es_datasheet'));
    
    const [ esTypeCover, setEsTypeCover ]   = useState(InitialData('es_type_cover'));
    const [ esFile, setEsFile ]             = useState(InitialData('es_file'));
    const [ esStatusFile, setEsStatusFile ] = useState(false);
    const [ esPositionX, setEsPositionX ]   = useState('');
    const [ esPositionY, setEsPositionY ]   = useState('');
    const [ esWidth, setEsWidth ]           = useState('');
    const [ esHeight, setEsHeight ]         = useState('');
    const [ esTypeCrop, setEsTypeCrop ]     = useState('');
    const [ esShowWidth, setEsShowWidth ]   = useState('');
    const [ esWidthReal, setEsWidthReal ]   = useState('');

    const [ namePageIdioma, setNamePageIdioma ] = useState([
        {
            "en": "To save",
            "es": "Ahorrar"
        },
        {
            "en": "Fields left blank will not appear on the site. Fields marked with an asterisk (*) are mandatory.",
            "es": "Los campos dejados en blanco no aparecerán en el sitio. Los campos marcados con un asterisco son obligatorios."
        },
        {
            "en": "Normal gallery",
            "es": "Galería normal"
        },
        {
            "en": "Open gallery",
            "es": "Abrir galería"
        },
        {
            "en": "Gallery Template",
            "es": "Plantilla de galería"
        },
        {
            "en": "Gallery",
            "es": "Galería"
        },
        {
            "en": "image",
            "es": "imagen"
        },
        {
            "en": "Title",
            "es": "Título"
        },
        {
            "en": "Image added",
            "es": "Imagen agregada"
        },
        {
            "en": "Add image",
            "es": "Añadir imagen"
        },
        {
            "en": "Type",
            "es": "Tipo"
        },
        {
            "en": "Link",
            "es": "Enlace"
        },
        {
            "en": "Caption",
            "es": "Subtítulo"
        }
    ]);

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage('portfolio', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{    
        setIdioma(GetListPag('idioma'));
        setDataPage(GetDataPage("portfolio"));
        setIdPage(GetListPag('currentPageId'));

        // en
        setEnShowGallery(InitialData('en_show_gallery'));
        setEnGalleryTitle(InitialData('en_gallery_title'));
        setEnGalleryText(InitialData('en_gallery_text'));
        setEnGalleryFile(InitialData('en_gallery_file'));
        setEnGalleryStatusFile(false);

        setEnProject(InitialData('en_project'));
        setEnSubtitle(InitialData('en_subtitle'));
        setEnType(InitialData('en_type'));
        setEnColorPage(InitialData('en_color'));
        setEnYear(InitialData('en_year'));
        setEnClient(InitialData('en_client'));
        setEnText(InitialData('en_text'));
        setEnTypeCover(InitialData('en_type_cover'));
        setEnContents(InitialData('en_contents'));
        setEnDatasheet(InitialData('en_datasheet'));
        
        setEnFile(InitialData('en_file'));
        setEnStatusFile(false);
        setEnPositionX('');
        setEnPositionY('');
        setEnWidth('');
        setEnHeight('');
        setEnTypeCrop('');
        setEnShowWidth('');
        setEnWidthReal('');
        
        // es
        setEsShowGallery(InitialData('es_show_gallery'));
        setEsGalleryTitle(InitialData('es_gallery_title'));
        setEsGalleryText(InitialData('es_gallery_text'));
        setEsGalleryFile(InitialData('es_gallery_file'));
        setEsGalleryStatusFile(false);

        setEsProject(InitialData('es_project'));
        setEsSubtitle(InitialData('es_subtitle'));
        setEsType(InitialData('es_type'));
        setEsColorPage(InitialData('es_color'));
        setEsYear(InitialData('es_year'));
        setEsClient(InitialData('es_client'));
        setEsText(InitialData('es_text'));
        setEsTypeCover(InitialData('es_type_cover'));
        setEsContents(InitialData('es_contents'));
        setEsDatasheet(InitialData('es_datasheet'));
        
        setEsFile(InitialData('es_file'));
        setEsStatusFile(false);
        setEsPositionX('');
        setEsPositionY('');
        setEsWidth('');
        setEsHeight('');
        setEsTypeCrop('');
        setEsShowWidth('');
        setEsWidthReal('');
    }, [idPage, dataPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'en_type_cover' || type == 'es_type_cover'){
            return 'Image';
        }
        if(type == 'en_contents' || type == 'es_contents'){
            return [];
        }
        if(type == 'en_show_gallery' || type == 'es_show_gallery'){
            return false;
        }
        return '';
    }

    function ClickIdioma(value){
        setIdioma(value);
        SetListPag('idioma', value);
    }
    
    function ShowExample(type){
        SetModalData("ShowFile", { "type": "showImg", "file": "example/text_" + type + ".jpg" });
        SetModalState('ShowFile', true);
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function PopUpCropCover(value){
        SetModalData('PhotoCrop', { "origin": "cover", "qtd": 1, "file": value, "showImg": URL.createObjectURL(value), "RegisterCropData": RegisterCropData });
        SetModalState('PhotoCrop', true);
    }

    function PopUpCrop(index, index_1, value){
        if(idioma == 'en'){
            const newData = [...enContents];        
            SetModalData('PhotoCrop', { "origin": "portfolio", "qtd": newData[index]['contents'].length, "index": index, "index_1": index_1, "file": value, "showImg": URL.createObjectURL(value), "RegisterCropData": RegisterCropData });
            SetModalState('PhotoCrop', true);

        }else if(idioma == 'es'){
            const newData = [...esContents];        
            SetModalData('PhotoCrop', { "origin": "portfolio", "qtd": newData[index]['contents'].length, "index": index, "index_1": index_1, "file": value, "showImg": URL.createObjectURL(value), "RegisterCropData": RegisterCropData });
            SetModalState('PhotoCrop', true);
        }
    }

    function RegisterCropData(img){
        if(idioma == 'en'){
            if(img.type == "cover"){
                setEnStatusFile(true);
                setEnFile(img.file);
                setEnPositionX(img.positionX);
                setEnPositionY(img.positionY);
                setEnWidth(img.width);
                setEnHeight(img.height);
                setEnTypeCrop(img.typeCrop);
                setEnShowWidth(img.showWidth);
                setEnWidthReal(img.widthReal);

            }else {
                const newData = [...enContents];
                newData[img.index]['contents'][img.index_1]['statusFile'] = true;
                newData[img.index]['contents'][img.index_1]['file']      = img.file;
                newData[img.index]['contents'][img.index_1]['positionX'] = img.positionX;
                newData[img.index]['contents'][img.index_1]['positionY'] = img.positionY;
                newData[img.index]['contents'][img.index_1]['width']     = img.width;
                newData[img.index]['contents'][img.index_1]['height']    = img.height;
                newData[img.index]['contents'][img.index_1]['typeCrop']  = img.typeCrop;
                newData[img.index]['contents'][img.index_1]['showWidth'] = img.showWidth;
                newData[img.index]['contents'][img.index_1]['widthReal'] = img.widthReal;
                setEnContents(newData);
            }

        }else if(idioma == 'es'){
            if(img.type == "cover"){
                setEsStatusFile(true);
                setEsFile(img.file);
                setEsPositionX(img.positionX);
                setEsPositionY(img.positionY);
                setEsWidth(img.width);
                setEsHeight(img.height);
                setEsTypeCrop(img.typeCrop);
                setEsShowWidth(img.showWidth);
                setEsWidthReal(img.widthReal);

            }else {
                const newData = [...esContents];
                newData[img.index]['contents'][img.index_1]['statusFile'] = true;
                newData[img.index]['contents'][img.index_1]['file']      = img.file;
                newData[img.index]['contents'][img.index_1]['positionX'] = img.positionX;
                newData[img.index]['contents'][img.index_1]['positionY'] = img.positionY;
                newData[img.index]['contents'][img.index_1]['width']     = img.width;
                newData[img.index]['contents'][img.index_1]['height']    = img.height;
                newData[img.index]['contents'][img.index_1]['typeCrop']  = img.typeCrop;
                newData[img.index]['contents'][img.index_1]['showWidth'] = img.showWidth;
                newData[img.index]['contents'][img.index_1]['widthReal'] = img.widthReal;
                setEsContents(newData);
            }
        }
    }

    function AddNewBlock(){
        if(idioma == 'en'){
            setEnContents([...enContents, { "id": 0, "type": 0, "container": false, "status": false, "height": "center", "width": "left", "contents": [ ] }]);

        }else if(idioma == 'es'){
            setEsContents([...esContents, { "id": 0, "type": 0, "container": false, "status": false, "height": "center", "width": "left", "contents": [ ] }]);
        }
    }

    function StatusContainer(index, value){
        if(idioma == 'en'){
            const newData = [...enContents];
            newData[index].container = value;
            setEnContents(newData);

        }else if(idioma == 'es'){
            const newData = [...esContents];
            newData[index].container = value;
            setEsContents(newData);
        }
    }

    function StatusAlign(index, value){
        if(idioma == 'en'){
            const newData = [...enContents];
            newData[index].status = value;
            setEnContents(newData);

        }else if(idioma == 'es'){
            const newData = [...esContents];
            newData[index].status = value;
            setEsContents(newData);
        }
    }

    function AltAlign(index, height, width){
        if(idioma == 'en'){
            const newData = [...enContents];
            newData[index].height = height;
            newData[index].width  = width;
            newData[index].status  = false;
            setEnContents(newData);

        }else if(idioma == 'es'){
            const newData = [...esContents];
            newData[index].height = height;
            newData[index].width  = width;
            newData[index].status  = false;
            setEsContents(newData);
        }
    }

    function RemuveContent(index, id){
        if(idioma == 'en'){
            if(id == 0){
                const newData = [...enContents];
                newData.splice(index, 1);
                setEnContents(newData);
            }else {
                SetModalData("Confirmation", { "id": id, "origin": "portfolio", "type": "delete_contents", "name": "Content" });
                SetModalState('Confirmation', true);
            }

        }else if(idioma == 'es'){
            if(id == 0){
                const newData = [...esContents];
                newData.splice(index, 1);
                setEsContents(newData);
            }else {
                SetModalData("Confirmation", { "id": id, "origin": "portfolio", "type": "delete_contents", "name": "Conteúdo" });
                SetModalState('Confirmation', true);
            }
        }
    }

    function ShowTypeAdd(index, qtd){
        if(idioma == 'en'){
            const newData = [...enContents];
            newData[index].type = qtd;
            for (let i = 0; i < qtd; i++) {
                newData[index].contents.push({ "id": 0, "type": "", "status": false, "height": "top", "width": "left", "gallery": [] });
            }
            setEnContents(newData);

        }else if(idioma == 'es'){
            const newData = [...esContents];
            newData[index].type = qtd;
            for (let i = 0; i < qtd; i++) {
                newData[index].contents.push({ "id": 0, "type": "", "status": false, "height": "top", "width": "left", "gallery": [] });
            }
            setEsContents(newData);
        }
    }

    function NewDataContents(type, index, index_1){
        if(idioma == 'en'){
            const newData = [...enContents];
            newData[index].contents[index_1].type = type;
            setEnContents(newData);

        }else if(idioma == 'es'){
            const newData = [...esContents];
            newData[index].contents[index_1].type = type;
            setEsContents(newData);
        }
    }

    function AltTypeContent(index, index_1){
        if(idioma == 'en'){
            const newData = [...enContents];
            newData[index].contents[index_1].type = '';
            setEnContents(newData);

        }else if(idioma == 'es'){
            const newData = [...esContents];
            newData[index].contents[index_1].type = '';
            setEsContents(newData);
        }
    }

    function StateAlignContent(index_0, index_1, value){
        if(idioma == 'en'){
            const newData = [...enContents];
            newData[index_0].contents[index_1].status = value;
            setEnContents(newData);

        }else if(idioma == 'es'){
            const newData = [...esContents];
            newData[index_0].contents[index_1].status = value;
            setEsContents(newData);
        }
    }

    function AltAlignContent(index_0, index_1, height, width){
        if(idioma == 'en'){
            const newData = [...enContents];
            newData[index_0].contents[index_1].height = height;
            newData[index_0].contents[index_1].width  = width;
            newData[index_0].contents[index_1].status = false;
            setEnContents(newData);

        }else if(idioma == 'es'){
            const newData = [...esContents];
            newData[index_0].contents[index_1].height = height;
            newData[index_0].contents[index_1].width  = width;
            newData[index_0].contents[index_1].status = false;
            setEsContents(newData);
        }
    }

    function NewImgGallery(index_0, index_1){
        // en
        if(idioma == 'en'){
            const newData = [...enContents];
            newData[index_0]['contents'][index_1]['gallery'].push({ "id": 0, "index_0": index_0, "index_1": index_1, "title": "", "text": "", "file": "", "status": false });
            setEnContents(newData);
        }

        // es
        if(idioma == 'es'){
            const newData = [...esContents];
            newData[index_0]['contents'][index_1]['gallery'].push({ "id": 0, "index_0": index_0, "index_1": index_1, "title": "", "text": "", "file": "", "status": false });
            setEsContents(newData);
        }
    }

    function HandleGallery(type, index_0, index_1, index_2, value){        
        // en
        if(idioma == 'en'){
            const newData = [...enContents];
            if(type == 'file'){
                if(value){
                    newData[index_0]['contents'][index_1]['gallery'][index_2][type]     = value;
                    newData[index_0]['contents'][index_1]['gallery'][index_2]['status'] = true;
                }else {                
                    newData[index_0]['contents'][index_1]['gallery'][index_2][type]     = "";
                    newData[index_0]['contents'][index_1]['gallery'][index_2]['status'] = false;
                }
            }else {
                newData[index_0]['contents'][index_1]['gallery'][index_2][type] = value;
            }
            setEnContents(newData);
        }
        
        // es
        if(idioma == 'es'){
            const newData = [...esContents];
            if(type == 'file'){
                if(value){
                    newData[index_0]['contents'][index_1]['gallery'][index_2][type]     = value;
                    newData[index_0]['contents'][index_1]['gallery'][index_2]['status'] = true;
                }else {                
                    newData[index_0]['contents'][index_1]['gallery'][index_2][type]     = "";
                    newData[index_0]['contents'][index_1]['gallery'][index_2]['status'] = false;
                }
            }else {
                newData[index_0]['contents'][index_1]['gallery'][index_2][type] = value;
            }
            setEsContents(newData);
        }
    }

    function DeleteData(id, index_0, index_1, index_2, value){
        if(id == 0){            
            if(idioma == 'en'){
                const newData = [...enContents];
                newData[index_0]['contents'][index_1]['gallery'].splice(index_2, 1);
                setEnContents(newData);       
            }

            if(idioma == 'es'){
                const newData = [...esContents];
                newData[index_0]['contents'][index_1]['gallery'].splice(index_2, 1);
                setEsContents(newData);          
            }
        }else {
            SetModalData("Confirmation", { "id": id, "origin": 'portfolio', "type": "delete_gallery", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function ShowDataType(type, index, index_1, id_content, id_data){
        let newData = '';
        if(idioma == 'en'){
            newData = [...enContents];

        }else if(idioma == 'es'){
            newData = [...esContents];
        }
        
        switch (type) {
            case "gallery":
                return(
                    <>
                        <div className="list_input_data">
                            <div className="div_input">
                                <select className="space_select model_gallery" onChange={ (e)=>{ HandleDataContents('model_gallery', index, index_1, e.target.value) } } value={ newData[index]['contents'][index_1]['model_gallery'] } required>
                                    <option value="Normal gallery">{ namePageIdioma[2][idioma] }</option>
                                    <option value="Open gallery">{ namePageIdioma[3][idioma] }</option>
                                </select>
                                <span className="name_input">{ namePageIdioma[4][idioma] }</span>
                            </div>
                        </div>
                        <div className="list_input_data">
                            <div className="div_input space_div show_data_text">
                                <div className="div_subtitle_add">
                                    <div className="">{ namePageIdioma[5][idioma] }</div>
                                    <div className="new_data_page" onClick={ ()=>{ NewImgGallery(index, index_1) } }>
                                        <span className="icons_menu">+</span> { namePageIdioma[6][idioma] }
                                    </div>
                                </div>
                                {
                                    newData[index]['contents'][index_1]['gallery'].map((key, index_2)=>{
                                        return(
                                            <div className="div_input space_div show_data_text" key={ index_2 }>
                                                <div className="div_data_project">
                                                    <div className="div_input space_top">
                                                        <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleGallery('title', index, index_1, index_2, e.target.value) } } value={ key.title } />
                                                        <span className="name_input">{ namePageIdioma[7][idioma] }</span>
                                                    </div>
                                                    <div className="div_input space_top">
                                                        <div className={ key.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                            <label>
                                                                { key.status == true ? namePageIdioma[8][idioma] : namePageIdioma[9][idioma] }
                                                                <input type="file" onChange={ (e)=>{ HandleGallery('file', index, index_1, index_2, e.target.files[0]); } } accept="image/*" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {
                                                        key.file  ?
                                                            key.status == true ?
                                                            <div className="div_input space_icons" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.file)) } }>
                                                                <SvgImg color="#324d6b" className="icons_project"/>
                                                            </div> :
                                                            <div className="div_input space_icons" onClick={ ()=>{ OpenFile('showImg', "service/" + key.file) } }>
                                                                <SvgImg color="#324d6b" className="icons_project"/>
                                                            </div>
                                                        : null
                                                    }
                                                    <div className="div_input space_icons" onClick={ ()=>{ DeleteData(key.id, index, index_1, index_2, 'Imagem') } } style={ { display: 'flex' } }>
                                                        <SvgDelete color="#f00000" className="icons" />
                                                    </div>
                                                </div>
                                                {
                                                    newData[index]['contents'][index_1]['model_gallery'] == 'Open gallery' ? null :
                                                    <div className="div_input">
                                                        <JoditEditor className="show_textarea" config={ config } value={ key.text ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleGallery('text', index, index_1, index_2, newContent) } />
                                                    </div>
                                                }    
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </>
                )

            case "highlight_1": case "highlight_2": case "highlight_3":
                return (
                    <>
                        <div className="div_data_project">
                            <div className="div_input space_div space_top">
                                <input type="text" className="" maxLength="140" onChange={ (e)=>{ HandleDataContents('title', index, index_1, e.target.value) } } value={ newData[index]['contents'][index_1]['title'] } />
                                <span className="name_input">{ namePageIdioma[7][idioma] }</span>
                            </div>
                        </div>
                        <div className="div_input">
                            <JoditEditor className="show_textarea" config={ configText } value={ newData[index]['contents'][index_1]['text'] ? newData[index]['contents'][index_1]['text'].replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, index_1, newContent) } />
                        </div>
                    </>
                );

            case "highlight_4":
                return (
                    <>
                        <div className="div_data_project">
                            <div className="div_input space_div space_top">
                                <input type="text" className="" maxLength="140" onChange={ (e)=>{ HandleDataContents('subtitle', index, index_1, e.target.value) } } value={ newData[index]['contents'][index_1]['subtitle'] } />
                                <span className="name_input">{ namePageIdioma[12][idioma] }</span>
                            </div>
                        </div>
                        <div className="div_input">
                            <JoditEditor className="show_textarea" config={ configText } value={ newData[index]['contents'][index_1]['text'] ? newData[index]['contents'][index_1]['text'].replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, index_1, newContent) } />
                        </div>
                        <div className="div_input">
                            <JoditEditor className="show_textarea" config={ configText } value={ newData[index]['contents'][index_1]['text_1'] ? newData[index]['contents'][index_1]['text_1'].replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text_1', index, index_1, newContent) } />
                        </div>
                    </>
                );
                    
            case "text":
                return (
                    <div className="div_input">
                        <JoditEditor className="show_textarea" config={ configText } value={ newData[index]['contents'][index_1]['text'] ? newData[index]['contents'][index_1]['text'].replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, index_1, newContent) } />
                    </div>
                )

            case "img":
                return(
                    <div className="div_data_project">
                        <div className="div_input space_top">
                            <input type="text" className="name_img" maxLength="60" onChange={ (e)=>{ HandleDataContents('title', index, index_1, e.target.value) } } value={ newData[index]['contents'][index_1]['title'] } />
                            <span className="name_input">{ namePageIdioma[7][idioma] }</span>
                        </div>
                        <div className="div_input space_top">
                            <div className={ newData[index]['contents'][index_1]['statusFile'] == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { newData[index]['contents'][index_1]['statusFile'] == true ? namePageIdioma[8][idioma] : namePageIdioma[9][idioma] }
                                    <input type="file" onChange={ (e)=>{ PopUpCrop(index, index_1, e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                        </div>
                        {
                            newData[index]['contents'][index_1]['file']  ?
                                newData[index]['contents'][index_1]['statusFile'] == true ?
                                <div className="div_input space_icons" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(newData[index]['contents'][index_1]['file'])) } }>
                                    <SvgImg color="#324d6b" className="icons_project"/>
                                </div> :
                                <div className="div_input space_icons" onClick={ ()=>{ OpenFile('showImg', "img/" + newData[index]['contents'][index_1]['file']) } }>
                                    <SvgImg color="#324d6b" className="icons_project"/>
                                </div>
                            : null
                        }
                    </div>
                )

            case "video":
                return(
                    <>
                        <div className="div_data_project">
                            <div className="div_input space_top">
                                <select className="space_select" onChange={ (e)=>{ HandleDataContents('type_video', index, index_1, e.target.value) } } value={ newData[index]['contents'][index_1]['type_video'] } required>
                                    <option value="">#</option>
                                    <option value="Vimeo">Vimeo</option>
                                    <option value="Youtube">Youtube</option>
                                </select>
                                <span className="name_input">{ namePageIdioma[10][idioma] }</span>
                            </div>
                            <div className="div_input space_div space_top">
                                <input type="text" className="" maxLength="140" onChange={ (e)=>{ HandleDataContents('title', index, index_1, e.target.value) } } value={ newData[index]['contents'][index_1]['title'] } />
                                <span className="name_input">{ namePageIdioma[7][idioma] }</span>
                            </div>
                        </div>
                        <div className="div_data_project">
                            <div className="div_input space_div space_top">
                                <input type="text" className="" maxLength="140" onChange={ (e)=>{ HandleDataContents('link', index, index_1, e.target.value) } } value={ newData[index]['contents'][index_1]['link'] ? newData[index]['contents'][index_1]['link'].replaceAll(' ', '') : "" } required />
                                <span className="name_input">{ namePageIdioma[11][idioma] }</span>
                            </div>
                            {
                                newData[index]['contents'][index_1]['link'] == '' ? null :
                                <div className="div_input space_top" onClick={ ()=>{ OpenFile(newData[index]['contents'][index_1]['type_video'], newData[index]['contents'][index_1]['link']) } }>
                                    {
                                        newData[index]['contents'][index_1]['type_video'] == 'Drive' ? 
                                        <SvgGoogleDrive color="#324d6b" className="show_icons_project" /> :
                                            newData[index]['contents'][index_1]['type_video'] == 'Vimeo' ?  
                                            <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                newData[index]['contents'][index_1]['type_video'] == 'Youtube' ? 
                                                <SvgYoutube color="#324d6b" className="show_icons_project"/> : null
                                    }
                                </div>
                            }
                        </div>
                    </>
                )
        }
    }

    function HandleDataContents(type, index_0, index_1, value){
        if(idioma == 'en'){
            const newData = [...enContents];
            newData[index_0].contents[index_1][type] = value;
            setEnContents(newData);

        }else if(idioma == 'es'){
            const newData = [...esContents];
            newData[index_0].contents[index_1][type] = value;
            setEsContents(newData);
        }
    }

    function AddFile(value){
        if(idioma == 'en'){
            if(value){
                setEnGalleryFile(value);
                setEnGalleryStatusFile(true);
            }else {
                setEnGalleryFile('');
                setEnGalleryStatusFile(false);
            }
        }else if(idioma == 'es'){
            if(value){
                setEsGalleryFile(value);
                setEsGalleryStatusFile(true);
            }else {
                setEsGalleryFile('');
                setEsGalleryStatusFile(false);
            }
        }
    }

    function SelectedAlignmentType(type){
        switch (type) {
            case "left":
                return <SvgText color="#E51D4D" className="icons" />;
            case "center":  
                return <SvgText_Center color="#E51D4D" className="icons" />;
            case "right":  
                return <SvgText_Right color="#E51D4D" className="icons" />;
        }
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_RegisterPortfolio(userId, idPage, enShowGallery, enGalleryTitle, enGalleryText, enGalleryFile, enProject, enSubtitle, enType, enColorPage, enYear, enClient, enText, enTypeCover, enContents, enDatasheet, enFile, enPositionX, enPositionY, enWidth, enHeight, enTypeCrop, enShowWidth, enWidthReal, esShowGallery, esGalleryTitle, esGalleryText, esGalleryFile, esProject, esSubtitle, esType, esColorPage, esYear, esClient, esText, esTypeCover, esContents, esDatasheet, esFile, esPositionX, esPositionY, esWidth, esHeight, esTypeCrop, esShowWidth, esWidthReal, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'portfolio');
        SetListPag('currentPageId', 'remuve');
    }

    function Duplicate(){
        SetModalData("Confirmation", { "id": idPage, "origin": "portfolio", "idioma": idioma, "type": "duplicate" });
        SetModalState('Confirmation', true);
    }
    
    return(
        <div className="Page_Portfolio_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Portfolio
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Details
                        </div>
                    </div>
                    <div className="list_opt_alt_page">
                        <div className={ idioma == 'en' ? "type_idioma idioma_selected" : "type_idioma" } onClick={ ()=>{ ClickIdioma('en') } }>en</div>
                        <div className={ idioma == 'es' ? "type_idioma idioma_selected" : "type_idioma" } onClick={ ()=>{ ClickIdioma('es') } }>es</div>
                        <button className="new_block_text">
                            <div className="">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="">
                                { namePageIdioma[0][idioma] }
                            </div>
                        </button>
                    </div>
                </div>
                <div className="notification">
                    { namePageIdioma[1][idioma] }
                </div>
                {
                    idioma == 'en' ? 
                    <>
                        <div className="show_page_data">
                            <div className="list_type_data">
                                <div className="type_title">Data for gallery</div>
                                <div className={ enShowGallery == true ? "duplicate cursor_pointer status_name active" : "duplicate cursor_pointer status_name disabled" } onClick={ ()=>{ setEnShowGallery(!enShowGallery) } }>
                                    {
                                        enShowGallery == true ? "Show gallery" : "Do not show in gallery"
                                    }
                                </div>
                            </div>
                            <div className="list_input_data" style={ { marginBottom: '10px' } }>
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ setEnGalleryTitle(e.target.value) } } value={ enGalleryTitle } maxLength="20" />
                                    <span className="name_input">Title</span>
                                </div>
                                <div className={ enGalleryStatusFile == true ? "div_input new_file_add new_file_add_active" : "div_input new_file_add" }>
                                    <label>
                                        { enGalleryStatusFile == true ? "Image added" : "Add image" }
                                        <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    enGalleryFile !='' ?
                                        enGalleryStatusFile == true ?
                                        <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(enGalleryFile)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg', "cover/" + enGalleryFile) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    : null
                                }
                            </div>
                            <div className="list_input_data" style={ { marginBottom: '10px' } }>
                                <div className="div_input space_div">
                                    <JoditEditor className="show_textarea" config={ config } value={ enGalleryText.replaceAll('&#34;', '"') } onBlur={ newContent => setEnGalleryText(newContent) } />
                                </div>
                            </div>
                        </div>

                        <div className="show_page_data">
                            <div className="list_type_data">
                                <div className="type_title">Data according to language</div>
                                <div className="duplicate cursor_pointer" onClick={ ()=>{ Duplicate() } }>
                                    Copy data
                                </div>
                            </div>
                            <div className="list_input_data" style={ { marginBottom: '10px' } }>
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ setEnColorPage(e.target.value) } } value={ enColorPage } maxLength="20" required />
                                    <span className="name_input">Background color*</span>
                                </div>
                                <div className="div_input">
                                    <div className="show_color" style={ { backgroundColor: enColorPage } } />
                                </div>
                            </div>
                            <div className="list_input_data">
                                <div className="div_input">
                                    <input type="text" onChange={ (e)=>{ setEnSubtitle(e.target.value) } } value={ enSubtitle } maxLength="60" />
                                    <span className="name_input">Caption</span>
                                </div>
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ setEnProject(e.target.value) } } value={ enProject } maxLength="140" required />
                                    <span className="name_input">Project name*</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" onChange={ (e)=>{ setEnType(e.target.value) } } value={ enType } maxLength="60" required />
                                    <span className="name_input">Type*</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" className="year" onChange={ (e)=>{ setEnYear(e.target.value) } } value={ enYear.replaceAll(/\D/g, '') } maxLength="4" required />
                                    <span className="name_input">Year*</span>
                                </div>
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ setEnClient(e.target.value) } } value={ enClient } maxLength="140" required />
                                    <span className="name_input">Client name*</span>
                                </div>
                            </div>
                            <div className="list_input_data">                                
                                <div className="div_input">
                                    <select className="space_type_cover" onChange={ (e)=>{ setEnTypeCover(e.target.value) } } value={ enTypeCover } required>
                                        <option value="Image">Image</option>
                                        <option value="Vimeo">Video vimeo</option>
                                        <option value="Youtube">Video youtube</option>
                                    </select>
                                    <span className="name_input">Type cover*</span>
                                </div>
                                {
                                    enTypeCover != "Image" ?
                                    <>
                                        <div className="div_input space_div">
                                            <input type="text" onChange={ (e)=>{ setEnFile(e.target.value) } } value={ enFile } required />
                                            <span className="name_input">Code video*</span>
                                        </div>
                                        {
                                            enFile == '' ? null :
                                            <div className="div_input space_top" onClick={ ()=>{ OpenFile(enTypeCover, enFile) } }>
                                                {
                                                    enTypeCover == 'Vimeo' ?  
                                                    <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                        enTypeCover == 'Youtube' ? 
                                                        <SvgYoutube color="#324d6b" className="show_icons_project"/> : null
                                                }
                                            </div>
                                        }
                                    </> : null
                                }
                            </div>    
                        </div>
                        {
                            enTypeCover == "Image" ?                                
                            enFile !='' ?
                                <div className="div_page_cover">
                                    <label className="div_page_cover_edit">
                                        <input type="file" onChange={ (e)=>{ PopUpCropCover(e.target.files[0]); } } className="close_file_edit" accept="image/*" placeholder="" title="" />
                                        <div>
                                            <SvgEdit color="#ffffff" className="icons" />
                                        </div>
                                    </label>
                                    {
                                        enStatusFile == true ?
                                        <div className="div_cover div_cover_img">
                                            <img alt="img" src={ URL.createObjectURL(enFile) } className="show_img_cover" />
                                        </div> :
                                        <div className="div_cover div_cover_img">
                                            <img alt="img" src={ "./assets/portfolio/" + enFile } className="show_img_cover" />
                                        </div>
                                    }
                                </div>
                                :
                                <label>
                                    <input type="file" onChange={ (e)=>{ PopUpCropCover(e.target.files[0]); } } className="close_file" accept="image/*" placeholder="" title="" />
                                    <div className="div_cover">
                                        <div className="div_cover_icons">
                                            <SvgImg color="#CDD1D6" className="icons" />
                                        </div>
                                        <div className="div_cover_title">Add a cover</div>
                                        <div className="div_cover_explication">
                                            Drag and drop here or <span className="div_cover_color">look for the file</span>
                                        </div>
                                        <div className="div_cover_desc">suggestion 1920x1080</div>
                                    </div>
                                </label>
                            : null
                        }

                        <div className="show_page_data">
                            <div className="list_type_data">
                                <div className="type_title">Layout</div>
                            </div>
                            <div className="list_input_data">
                                {
                                    enContents.map((key, index)=>{
                                        let numbContents = index + 1;
                                        if(numbContents <= 9){
                                            numbContents = 0 + "" + numbContents;
                                        }
                                        return(
                                            <div className="div_show_or_new_data" key={ index }>
                                                <div className="show_title">
                                                    <div className="title_div div_data_topic_project">
                                                        <div className="">#{ numbContents }</div>
                                                        <div className="div_switch">
                                                            <div className="">Fullwidth</div>
                                                            <div className="div_restricted_switch">
                                                                <label className="switch">
                                                                    <div className="switch_wrapper">
                                                                        <input type="checkbox" onChange={ (e)=>{ StatusContainer(index, !key.container) } } checked={ key.container } />
                                                                        <span className="switch_button"></span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="align_type_contents">
                                                        <div className="div_align">
                                                            <div className="div_type_align" onClick={ ()=>{ StatusAlign(index, !key.status) } }>
                                                                Align line
                                                                {
                                                                    SelectedAlignmentType(key.width == "" ? "left": key.width)
                                                                }
                                                            </div>
                                                            {
                                                                key.status == true ?
                                                                <div className="list_align">
                                                                    <div className="align">
                                                                        <div className={ key.height == "top" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'top', 'left') } } />
                                                                        <div className={ key.height == "top" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'top', 'center') } } />
                                                                        <div className={ key.height == "top" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'top', 'right') } } />
                                                                    </div>
                                                                    <div className="align">
                                                                        <div className={ key.height == "center" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'center', 'left') } } />
                                                                        <div className={ key.height == "center" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'center', 'center') } } />
                                                                        <div className={ key.height == "center" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'center', 'right') } } />
                                                                    </div>
                                                                    <div className="align">
                                                                        <div className={ key.height == "bottom" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'bottom', 'left') } } />
                                                                        <div className={ key.height == "bottom" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'bottom', 'center') } } />
                                                                        <div className={ key.height == "bottom" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'bottom', 'right') } } />
                                                                    </div>
                                                                </div>
                                                                : null
                                                            }
                                                        </div>
                                                        <div className="" onClick={ ()=>{ RemuveContent(index, key.id) } }>
                                                            <SvgDelete color="#E51D4D" className="icons" />
                                                        </div>
                                                    </div>
                                                    <div className="div_data_project">
                                                        {
                                                            key.contents.length == 0 ?
                                                            <div className="add_project">
                                                                <div className="list_content">
                                                                    <div className="div_add_type">
                                                                        <div className="list_opt">
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 1); }}>
                                                                                <SvgOpt_1 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 2); }}>
                                                                                <SvgOpt_2 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                            {/* 
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 3); }}>
                                                                                <SvgOpt_3 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 4); }}>
                                                                                <SvgOpt_4 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 5); }}>
                                                                                <SvgOpt_5 color="#CDD1D6" className="opt_icon"/>
                                                                            </div> 
                                                                            */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> :
                                                            <>
                                                                {
                                                                    key.contents.map((key_1, index_1)=>{
                                                                        return(
                                                                            <div className={ key_1.type == '' ? "add_project show_data_project" : "add_project show_data_project show_data_min_height" } key={ index_1 }>
                                                                                <div className="list_content">
                                                                                    {
                                                                                        key_1.type == '' ?
                                                                                        <div className="div_add_type">
                                                                                            {
                                                                                                // Appears only if adding 1 column
                                                                                                key.contents.length > 1 ? null : 
                                                                                                <>
                                                                                                    <div className="div_opt_add" onClick={ ()=>{ NewDataContents('gallery', index, index_1) } }>
                                                                                                        <div className="">
                                                                                                            <SvgImg color="#FFFFFF" className="icons" />
                                                                                                        </div>
                                                                                                        <div className="">Gallery</div>
                                                                                                    </div>
                                                                                                    <div className="div_opt_add">
                                                                                                        <div className="select_type_data" onClick={ ()=>{ NewDataContents('highlight_1', index, index_1) } }>
                                                                                                            <div className="">
                                                                                                                <SvgText color="#FFFFFF" className="icons" />
                                                                                                            </div>
                                                                                                            <div className="">Highlight 1</div>
                                                                                                        </div>
                                                                                                        <div className="show_inf_type" onClick={ ()=>{ ShowExample(1) } }>?</div>
                                                                                                    </div>
                                                                                                    <div className="div_opt_add">
                                                                                                        <div className="select_type_data" onClick={ ()=>{ NewDataContents('highlight_2', index, index_1) } }>
                                                                                                            <div className="">
                                                                                                                <SvgText color="#FFFFFF" className="icons" />
                                                                                                            </div>
                                                                                                            <div className="">Highlight 2</div>
                                                                                                        </div>
                                                                                                        <div className="show_inf_type" onClick={ ()=>{ ShowExample(2) } }>?</div>
                                                                                                    </div>
                                                                                                    <div className="div_opt_add">
                                                                                                        <div className="select_type_data" onClick={ ()=>{ NewDataContents('highlight_3', index, index_1) } }>
                                                                                                            <div className="">
                                                                                                                <SvgText color="#FFFFFF" className="icons" />
                                                                                                            </div>
                                                                                                            <div className="">Highlight 3</div>
                                                                                                        </div>
                                                                                                        <div className="show_inf_type" onClick={ ()=>{ ShowExample(3) } }>?</div>
                                                                                                    </div>
                                                                                                    <div className="div_opt_add">
                                                                                                        <div className="select_type_data" onClick={ ()=>{ NewDataContents('highlight_4', index, index_1) } }>
                                                                                                            <div className="">
                                                                                                                <SvgText color="#FFFFFF" className="icons" />
                                                                                                            </div>
                                                                                                            <div className="">Highlight 4</div>
                                                                                                        </div>
                                                                                                        <div className="show_inf_type" onClick={ ()=>{ ShowExample(4) } }>?</div>
                                                                                                    </div>
                                                                                                </> 
                                                                                            }
                                                                                            <div className="div_opt_add" onClick={ ()=>{ NewDataContents('text', index, index_1) } }>
                                                                                                <div className="">
                                                                                                    <SvgText color="#FFFFFF" className="icons" />
                                                                                                </div>
                                                                                                <div className="">
                                                                                                    Text
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="div_opt_add" onClick={ ()=>{ NewDataContents('img', index, index_1) } }>
                                                                                                <div className="">
                                                                                                    <SvgImg color="#FFFFFF" className="icons" />
                                                                                                </div>
                                                                                                <div className="">
                                                                                                    Image
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="div_opt_add" onClick={ ()=>{ NewDataContents('video', index, index_1) } }>
                                                                                                <div className="">
                                                                                                    <SvgYoutube color="#FFFFFF" className="icons" />
                                                                                                </div>
                                                                                                <div className="">
                                                                                                    Video
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="">
                                                                                            <div className="div_alt_data_contents">
                                                                                                <div className="" onClick={ ()=>{ AltTypeContent(index, index_1) } }>
                                                                                                    <SvgReturnCircle color="#E51D4D" className="icons" />
                                                                                                </div>
                                                                                                {
                                                                                                    key_1.type == 'gallery' ? null :
                                                                                                    <div className="div_align">
                                                                                                        {
                                                                                                            key_1.type == 'highlight_1' ?
                                                                                                            <div className="div_selected" onClick={ ()=>{ ShowExample(1) } }>
                                                                                                                <div className="">Highlight 1</div>
                                                                                                                <div className="show_type_selected">?</div>
                                                                                                            </div> :
                                                                                                                key_1.type == 'highlight_2' ?
                                                                                                                <div className="div_selected" onClick={ ()=>{ ShowExample(2) } }>
                                                                                                                    <div className="">Highlight 2</div>
                                                                                                                    <div className="show_type_selected">?</div>
                                                                                                                </div> :
                                                                                                                    key_1.type == 'highlight_3' ?
                                                                                                                    <div className="div_selected" onClick={ ()=>{ ShowExample(3) } }>
                                                                                                                        <div className="">Highlight 3</div>
                                                                                                                        <div className="show_type_selected">?</div>
                                                                                                                    </div> :
                                                                                                                        key_1.type == 'highlight_4' ?
                                                                                                                        <div className="div_selected" onClick={ ()=>{ ShowExample(4) } }>
                                                                                                                            <div className="">Highlight 4</div>
                                                                                                                            <div className="show_type_selected">?</div>
                                                                                                                        </div>
                                                                                                                        : null
                                                                                                        }
                                                                                                        <div className="" onClick={ ()=>{ StateAlignContent(index, index_1, !key_1.status) } }>
                                                                                                            {
                                                                                                                SelectedAlignmentType(key_1.width == "" ? "left": key_1.width)
                                                                                                            }
                                                                                                        </div>
                                                                                                        {
                                                                                                            key_1.status == true ?
                                                                                                                <div className="list_align">
                                                                                                                    <div className="align">
                                                                                                                        <div className={ key_1.height == "top" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'left') } } />
                                                                                                                        <div className={ key_1.height == "top" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'center') } } />
                                                                                                                        <div className={ key_1.height == "top" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'right') } } />
                                                                                                                    </div>
                                                                                                                    <div className="align">
                                                                                                                        <div className={ key_1.height == "center" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'left') } } />
                                                                                                                        <div className={ key_1.height == "center" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'center') } } />
                                                                                                                        <div className={ key_1.height == "center" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'right') } } />
                                                                                                                    </div>
                                                                                                                    <div className="align">
                                                                                                                        <div className={ key_1.height == "bottom" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'left') } } />
                                                                                                                        <div className={ key_1.height == "bottom" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'center') } } />
                                                                                                                        <div className={ key_1.height == "bottom" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'right') } } />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            : null
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                ShowDataType(key_1.type, index, index_1, key.id, key_1.id)
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="list_input_data">
                                <div className="add_project">
                                    <div className="add_data" onClick={ ()=>{ AddNewBlock() } }>
                                        <SvgAddData color="#F00000" className="icons" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> 
                    :
                    <>
                        <div className="show_page_data">
                            <div className="list_type_data">
                                <div className="type_title">Datos para la galería</div>
                                <div className={ enShowGallery == true ? "duplicate cursor_pointer status_name active" : "duplicate cursor_pointer status_name disabled" } onClick={ ()=>{ setEsShowGallery(!esShowGallery) } }>
                                    {
                                        enShowGallery == true ? "Mostrar na galería" : "Não mostrar na galería"
                                    }
                                </div>
                            </div>
                            <div className="list_input_data" style={ { marginBottom: '10px' } }>
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ setEsGalleryTitle(e.target.value) } } value={ esGalleryTitle } maxLength="20" />
                                    <span className="name_input">Título</span>
                                </div>
                                <div className={ esGalleryStatusFile == true ? "div_input new_file_add new_file_add_active" : "div_input new_file_add" }>
                                    <label>
                                        { esGalleryStatusFile == true ? "Imagen agregada" : "Añadir imagen" }
                                        <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    esGalleryFile !='' ?
                                        esGalleryStatusFile == true ?
                                        <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(esGalleryFile)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg', "cover/" + esGalleryFile) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    : null
                                }
                            </div>
                            <div className="list_input_data" style={ { marginBottom: '10px' } }>
                                <div className="div_input space_div">
                                    <JoditEditor className="show_textarea" config={ config } value={ esGalleryText.replaceAll('&#34;', '"') } onBlur={ newContent => setEsGalleryText(newContent) } />
                                </div>
                            </div>
                        </div>

                        <div className="show_page_data">  
                            <div className="list_type_data">
                                <div className="type_title">Datos según idioma</div>
                                <div className="duplicate cursor_pointer" onClick={ ()=>{ Duplicate() } }>
                                    Copiar datos
                                </div>
                            </div>
                            <div className="list_input_data" style={ { marginBottom: '10px' } }>
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ setEsColorPage(e.target.value) } } value={ esColorPage } maxLength="20" required />
                                    <span className="name_input">Color de fondo*</span>
                                </div> 
                                <div className="div_input">
                                    <div className="show_color" style={ { backgroundColor: esColorPage } } />
                                </div>
                            </div>
                            <div className="list_input_data">
                                <div className="div_input">
                                    <input type="text" onChange={ (e)=>{ setEsSubtitle(e.target.value) } } value={ esSubtitle } maxLength="60" />
                                    <span className="name_input">Subtítulo</span>
                                </div>
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ setEsProject(e.target.value) } } value={ esProject } maxLength="140" required />
                                    <span className="name_input">Nombre del proyecto*</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" onChange={ (e)=>{ setEsType(e.target.value) } } value={ esType } maxLength="60" required />
                                    <span className="name_input">Tipo*</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" className="year" onChange={ (e)=>{ setEsYear(e.target.value) } } value={ esYear.replaceAll(/\D/g, '') } maxLength="4" required />
                                    <span className="name_input">Año*</span>
                                </div>
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ setEsClient(e.target.value) } } value={ esClient } maxLength="140" required />
                                    <span className="name_input">Nombre del cliente*</span>
                                </div>
                            </div> 
                            <div className="list_input_data">                                
                                <div className="div_input">
                                    <select className="space_type_cover" onChange={ (e)=>{ setEsTypeCover(e.target.value) } } value={ esTypeCover } required>
                                        <option value="Image">Imagen</option>
                                        <option value="Vimeo">Video vimeo</option>
                                        <option value="Youtube">Video youtube</option>
                                    </select>
                                    <span className="name_input">Funda tipográfica*</span>
                                </div>
                                {
                                    esTypeCover != "Image" ?
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ setEsFile(e.target.value) } } value={ esFile } required />
                                        <span className="name_input">Código de vídeo*</span>
                                    </div> : null
                                }
                            </div>   
                        </div>
                        {
                            enTypeCover == "Image" ?
                            esFile !='' ?
                                <div className="div_page_cover">
                                    <label className="div_page_cover_edit">
                                        <input type="file" onChange={ (e)=>{ PopUpCropCover(e.target.files[0]); } } className="close_file_edit" accept="image/*" placeholder="" title="" />
                                        <div>
                                            <SvgEdit color="#ffffff" className="icons" />
                                        </div>
                                    </label>
                                    {
                                        esStatusFile == true ?
                                        <div className="div_cover div_cover_img">
                                            <img alt="img" src={ URL.createObjectURL(esFile) } className="show_img_cover" />
                                        </div> :
                                        <div className="div_cover div_cover_img">
                                            <img alt="img" src={ "./assets/portfolio/" + esFile } className="show_img_cover" />
                                        </div>
                                    }
                                </div>
                                :
                                <label>
                                    <input type="file" onChange={ (e)=>{ PopUpCropCover(e.target.files[0]); } } className="close_file" accept="image/*" placeholder="" title="" />
                                    <div className="div_cover">
                                        <div className="div_cover_icons">
                                            <SvgImg color="#CDD1D6" className="icons" />
                                        </div>
                                        <div className="div_cover_title">Agregar una cubierta</div>
                                        <div className="div_cover_explication">
                                            Arrastre y suelte aquí o <span className="div_cover_color">busque el archivo</span>
                                        </div>
                                        <div className="div_cover_desc">sugerencia 1920x1080</div>
                                    </div>
                                </label>
                            : null
                        }

                        <div className="show_page_data">
                            <div className="list_type_data">
                                <div className="type_title">Layout</div>
                            </div>
                            <div className="list_input_data">
                                {
                                    esContents.map((key, index)=>{
                                        let numbContents = index + 1;
                                        if(numbContents <= 9){
                                            numbContents = 0 + "" + numbContents;
                                        }
                                        return(
                                            <div className="div_show_or_new_data" key={ index }>
                                                <div className="show_title">
                                                    <div className="title_div div_data_topic_project">
                                                        <div className="">#{ numbContents }</div>
                                                        <div className="div_switch">
                                                            <div className="">Ancho completo</div>
                                                            <div className="div_restricted_switch">
                                                                <label className="switch">
                                                                    <div className="switch_wrapper">
                                                                        <input type="checkbox" onChange={ (e)=>{ StatusContainer(index, !key.container) } } checked={ key.container } />
                                                                        <span className="switch_button"></span>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="align_type_contents">
                                                        <div className="div_align">
                                                            <div className="div_type_align" onClick={ ()=>{ StatusAlign(index, !key.status) } }>
                                                                Alinear línea
                                                                {
                                                                    SelectedAlignmentType(key.width == "" ? "left": key.width)
                                                                }
                                                            </div>
                                                            {
                                                                key.status == true ?
                                                                <div className="list_align">
                                                                    <div className="align">
                                                                        <div className={ key.height == "top" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'top', 'left') } } />
                                                                        <div className={ key.height == "top" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'top', 'center') } } />
                                                                        <div className={ key.height == "top" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'top', 'right') } } />
                                                                    </div>
                                                                    <div className="align">
                                                                        <div className={ key.height == "center" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'center', 'left') } } />
                                                                        <div className={ key.height == "center" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'center', 'center') } } />
                                                                        <div className={ key.height == "center" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'center', 'right') } } />
                                                                    </div>
                                                                    <div className="align">
                                                                        <div className={ key.height == "bottom" && key.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlign(index, 'bottom', 'left') } } />
                                                                        <div className={ key.height == "bottom" && key.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlign(index, 'bottom', 'center') } } />
                                                                        <div className={ key.height == "bottom" && key.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlign(index, 'bottom', 'right') } } />
                                                                    </div>
                                                                </div>
                                                                : null
                                                            }
                                                        </div>
                                                        <div className="" onClick={ ()=>{ RemuveContent(index, key.id) } }>
                                                            <SvgDelete color="#E51D4D" className="icons" />
                                                        </div>
                                                    </div>
                                                    <div className="div_data_project">
                                                        {
                                                            key.contents.length == 0 ?
                                                            <div className="add_project">
                                                                <div className="list_content">
                                                                    <div className="div_add_type">
                                                                        <div className="list_opt">
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 1); }}>
                                                                                <SvgOpt_1 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 2); }}>
                                                                                <SvgOpt_2 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                            {/* 
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 3); }}>
                                                                                <SvgOpt_3 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 4); }}>
                                                                                <SvgOpt_4 color="#CDD1D6" className="opt_icon"/>
                                                                            </div>
                                                                            <div className="show_opt" onClick={ ()=>{ ShowTypeAdd(index, 5); }}>
                                                                                <SvgOpt_5 color="#CDD1D6" className="opt_icon"/>
                                                                            </div> 
                                                                            */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> :
                                                            <>
                                                                {
                                                                    key.contents.map((key_1, index_1)=>{
                                                                        return(
                                                                            <div className={ key_1.type == '' ? "add_project show_data_project" : "add_project show_data_project show_data_min_height" } key={ index_1 }>
                                                                                <div className="list_content">                                                                                    
                                                                                    {
                                                                                        key_1.type == '' ?
                                                                                        <div className="div_add_type">
                                                                                            {
                                                                                                // Appears only if adding 1 column
                                                                                                key.contents.length > 1 ? null : 
                                                                                                <>
                                                                                                    <div className="div_opt_add" onClick={ ()=>{ NewDataContents('gallery', index, index_1) } }>
                                                                                                        <div className="">
                                                                                                            <SvgImg color="#FFFFFF" className="icons" />
                                                                                                        </div>
                                                                                                        <div className="">Galería</div>
                                                                                                    </div>
                                                                                                    <div className="div_opt_add">
                                                                                                        <div className="select_type_data" onClick={ ()=>{ NewDataContents('highlight_1', index, index_1) } }>
                                                                                                            <div className="">
                                                                                                                <SvgText color="#FFFFFF" className="icons" />
                                                                                                            </div>
                                                                                                            <div className="">Destacar 1</div>
                                                                                                        </div>
                                                                                                        <div className="show_inf_type" onClick={ ()=>{ ShowExample(1) } }>?</div>
                                                                                                    </div>
                                                                                                    <div className="div_opt_add">
                                                                                                        <div className="select_type_data" onClick={ ()=>{ NewDataContents('highlight_2', index, index_1) } }>
                                                                                                            <div className="">
                                                                                                                <SvgText color="#FFFFFF" className="icons" />
                                                                                                            </div>
                                                                                                            <div className="">Destacar 2</div>
                                                                                                        </div>
                                                                                                        <div className="show_inf_type" onClick={ ()=>{ ShowExample(2) } }>?</div>
                                                                                                    </div>
                                                                                                    <div className="div_opt_add">
                                                                                                        <div className="select_type_data" onClick={ ()=>{ NewDataContents('highlight_3', index, index_1) } }>
                                                                                                            <div className="">
                                                                                                                <SvgText color="#FFFFFF" className="icons" />
                                                                                                            </div>
                                                                                                            <div className="">Destacar 3</div>
                                                                                                        </div>
                                                                                                        <div className="show_inf_type" onClick={ ()=>{ ShowExample(3) } }>?</div>
                                                                                                    </div>
                                                                                                    <div className="div_opt_add">
                                                                                                        <div className="select_type_data" onClick={ ()=>{ NewDataContents('highlight_4', index, index_1) } }>
                                                                                                            <div className="">
                                                                                                                <SvgText color="#FFFFFF" className="icons" />
                                                                                                            </div>
                                                                                                            <div className="">Destacar 4</div>
                                                                                                        </div>
                                                                                                        <div className="show_inf_type" onClick={ ()=>{ ShowExample(4) } }>?</div>
                                                                                                    </div>
                                                                                                </> 
                                                                                            }
                                                                                            <div className="div_opt_add" onClick={ ()=>{ NewDataContents('text', index, index_1) } }>
                                                                                                <div className="">
                                                                                                    <SvgText color="#FFFFFF" className="icons" />
                                                                                                </div>
                                                                                                <div className="">
                                                                                                    Texto
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="div_opt_add" onClick={ ()=>{ NewDataContents('img', index, index_1) } }>
                                                                                                <div className="">
                                                                                                    <SvgImg color="#FFFFFF" className="icons" />
                                                                                                </div>
                                                                                                <div className="">
                                                                                                    Imagen
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="div_opt_add" onClick={ ()=>{ NewDataContents('video', index, index_1) } }>
                                                                                                <div className="">
                                                                                                    <SvgYoutube color="#FFFFFF" className="icons" />
                                                                                                </div>
                                                                                                <div className="">
                                                                                                    Video
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="">
                                                                                            <div className="div_alt_data_contents">
                                                                                                <div className="" onClick={ ()=>{ AltTypeContent(index, index_1) } }>
                                                                                                    <SvgReturnCircle color="#E51D4D" className="icons" />
                                                                                                </div>
                                                                                                {
                                                                                                    key_1.type == 'gallery' ? null :
                                                                                                    <div className="div_align">
                                                                                                        {
                                                                                                            key_1.type == 'highlight_1' ?
                                                                                                            <div className="div_selected" onClick={ ()=>{ ShowExample(1) } }>
                                                                                                                <div className="">Destacar 1</div>
                                                                                                                <div className="show_type_selected">?</div>
                                                                                                            </div> :
                                                                                                                key_1.type == 'highlight_2' ?
                                                                                                                <div className="div_selected" onClick={ ()=>{ ShowExample(2) } }>
                                                                                                                    <div className="">Destacar 2</div>
                                                                                                                    <div className="show_type_selected">?</div>
                                                                                                                </div> :
                                                                                                                    key_1.type == 'highlight_3' ?
                                                                                                                    <div className="div_selected" onClick={ ()=>{ ShowExample(3) } }>
                                                                                                                        <div className="">Destacar 3</div>
                                                                                                                        <div className="show_type_selected">?</div>
                                                                                                                    </div> :
                                                                                                                        key_1.type == 'highlight_4' ?
                                                                                                                        <div className="div_selected" onClick={ ()=>{ ShowExample(4) } }>
                                                                                                                            <div className="">Destacar 4</div>
                                                                                                                            <div className="show_type_selected">?</div>
                                                                                                                        </div>
                                                                                                                        : null
                                                                                                        }
                                                                                                        <div className="" onClick={ ()=>{ StateAlignContent(index, index_1, !key_1.status) } }>
                                                                                                            {
                                                                                                                SelectedAlignmentType(key_1.width == "" ? "left": key_1.width)
                                                                                                            }
                                                                                                        </div>
                                                                                                        {
                                                                                                            key_1.status == true ?
                                                                                                                <div className="list_align">
                                                                                                                    <div className="align">
                                                                                                                        <div className={ key_1.height == "top" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'left') } } />
                                                                                                                        <div className={ key_1.height == "top" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'center') } } />
                                                                                                                        <div className={ key_1.height == "top" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'top', 'right') } } />
                                                                                                                    </div>
                                                                                                                    <div className="align">
                                                                                                                        <div className={ key_1.height == "center" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'left') } } />
                                                                                                                        <div className={ key_1.height == "center" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'center') } } />
                                                                                                                        <div className={ key_1.height == "center" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'center', 'right') } } />
                                                                                                                    </div>
                                                                                                                    <div className="align">
                                                                                                                        <div className={ key_1.height == "bottom" && key_1.width == "left" ? "img_0 img_1 img_1_active" : "img_0 img_1" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'left') } } />
                                                                                                                        <div className={ key_1.height == "bottom" && key_1.width == "center" ? "img_0 img_2 img_2_active" : "img_0 img_2" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'center') } } />
                                                                                                                        <div className={ key_1.height == "bottom" && key_1.width == "right" ? "img_0 img_3 img_3_active" : "img_0 img_3" } onClick={ ()=>{ AltAlignContent(index, index_1, 'bottom', 'right') } } />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            : null
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                ShowDataType(key_1.type, index, index_1, key.id, key_1.id)
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="list_input_data">
                                <div className="add_project">
                                    <div className="add_data" onClick={ ()=>{ AddNewBlock() } }>
                                        <SvgAddData color="#F00000" className="icons" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </form>
        </div>
    )
}