import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgDelete, SvgImg, SvgSave, SvgSetaRight } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Reg_RegisterData } from "services/RegisterData";

import Model_Home from "components/Model/Home";
import Model_Text from "components/Model/Text";
import Model_Video from "components/Model/Video";
import Model_Gallery from "components/Model/Gallery";
import Model_OurServices from "components/Model/OurServices";
import Model_LogoClients from "components/Model/LogoClients";

export default function Page_Products_Details(){

    const [ loading, setLoading ]   = useState(false);

    const [ userId, setUserId ]         = useState(GetUserData('id'));
    const [ idioma, setIdioma ]         = useState(GetListPag('idioma'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));

    const [ dataPage, setDataPage ] = useState(GetDataPage("products"));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ title, setTitle ]       = useState(InitialData('title_page'));
    const [ color, setColor ]       = useState(InitialData('color'));
    const [ typePage, setTypePage ] = useState(InitialData('type_page'));

    // en
    const [ enContent, setEnContent ] = useState(InitialData('en_contents'));

    // es
    const [ esContent, setEsContent ] = useState(InitialData('es_contents'));

    const [ namePageIdioma, setNamePageIdioma ] = useState([
        {
            "en": "Details",
            "es": "Detalles"
        },
        {
            "en": "To save",
            "es": "Ahorrar"
        },
        {
            "en": "Page data",
            "es": "Datos de la página"
        },
        {
            "en": "Title",
            "es": "Título"
        },
        {
            "en": "Background color",
            "es": "Color de fondo"
        },
        {
            "en": "Page type",
            "es": "Tipo de página"
        },
        {
            "en": "Example of how the page will look",
            "es": "Ejemplo de cómo se verá la página"
        }
    ]);

    const [ pageList, setPageList ] = useState([
        {
            "key": "home",
            "name": {
                "en": "Home",
                "es": "Home"
            }
        },
        {
            "key": "text",
            "name": {
                "en": "Text",
                "es": "Texto"
            }
        },
        {
            "key": "text_link",
            "name": {
                "en": "Text link",
                "es": "Texto link"
            }
        },
        {
            "key": "gallery_model_1",
            "name": {
                "en": "Services",
                "es": "Servicios"
            }
        },
        {
            "key": "gallery_model_2",
            "name": {
                "en": "Gallery",
                "es": "Galería"
            }
        },
        {
            "key": "gallery_model_3",
            "name": {
                "en": "Testimonials",
                "es": "Testimonials"
            }
        },
        {
            "key": "video",
            "name": {
                "en": "Video",
                "es": "Video"
            }
        },
        {
            "key": "our_services",
            "name": {
                "en": "Our services",
                "es": "Nuestros servicios"
            }
        },
        {
            "key": "logo_clients",
            "name": {
                "en": "Logotipo clients",
                "es": "Logotipo clientes"
            }
        }
    ]);
    
    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterUserData('access', setUserAccess);
        RegisterDataPage('products', setDataPage);
        RegisterListPag('idioma', setIdioma);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("products"));
        setIdioma(GetListPag('idioma'));
        setIdPage(GetListPag('currentPageId'));

        setTitle(InitialData('title_page'));
        setColor(InitialData('color'));
        setTypePage(InitialData('type_page'));

        // en
        setEnContent(InitialData('en_contents'));
        // es
        setEsContent(InitialData('es_contents'));
    }, [dataPage, idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'en_contents' || type == 'es_contents'){
            return [];
        }
        return '';
    }

    function ClickIdioma(value){
        setIdioma(value);
        SetListPag('idioma', value);
    }

    function RegisterData(idioma_data, value){
        // en
        if(idioma_data == 'en'){
            setEnContent(value);
        }
        // es
        if(idioma_data == 'es'){
            setEsContent(value);
        }
    }

    function CurrentPage(){
        switch (typePage) {
            case "home":
                return <Model_Home origin="products" RegisterData={ RegisterData } />;

            case "text":
                return <Model_Text origin="products" type="1" RegisterData={ RegisterData } />;

            case "text_link":
                return <Model_Text origin="products" type="2" RegisterData={ RegisterData } />;

            case "gallery_model_1":
                return <Model_Gallery origin="products" type="1" RegisterData={ RegisterData } />;

            case "gallery_model_2":
                return <Model_Gallery origin="products" type="2" RegisterData={ RegisterData } />;

            case "gallery_model_3":
                return <Model_Gallery origin="products" type="3" RegisterData={ RegisterData } />;

            case "video":
                return <Model_Video origin="products" RegisterData={ RegisterData } />;

            case "our_services":
                return <Model_OurServices origin="products" RegisterData={ RegisterData } />;

            case "logo_clients":
                return <Model_LogoClients origin="products" RegisterData={ RegisterData } />;
        }
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_RegisterData('products', userId, idPage, title, color, typePage, enContent, esContent, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'products');
        SetListPag('currentPageId', 'remuve');
    }

    function ShowPreviewPage(value){
        switch (value) {
            case "home":             
                return(
                    <div className="div_img_example">
                        <img alt="img_1" src="./assets/example/img_1.jpg" className="img_example" />
                    </div>
                )

            case "text":             
                return(
                    <div className="div_img_example">
                        <img alt="img_1" src="./assets/example/img_2.jpg" className="img_example" />
                    </div>
                )

            case "text_link":             
                return(
                    <div className="div_img_example">
                        <img alt="img_1" src="./assets/example/img_5.jpg" className="img_example" />
                    </div>
                )

            case "gallery_model_1":             
                return(
                    <div className="div_img_example">
                        <img alt="img_1" src="./assets/example/img_3.jpg" className="img_example" />
                    </div>
                )

            case "gallery_model_2":             
                return(
                    <div className="div_img_example">
                        <img alt="img_1" src="./assets/example/img_4.jpg" className="img_example" />
                    </div>
                )

            case "gallery_model_3":             
                return(
                    <div className="div_img_example">
                        <img alt="img_1" src="./assets/example/img_6.jpg" className="img_example" />
                    </div>
                )

            case "video":             
                return(
                    <div className="div_img_example">
                        <img alt="img_1" src="./assets/example/img_7.jpg" className="img_example" />
                    </div>
                )

            case "our_services":             
                return(
                    <div className="div_img_example">
                        <img alt="img_1" src="./assets/example/img_8.jpg" className="img_example" />
                    </div>
                )

            case "logo_clients": 
                return(
                    <div className="div_img_example">
                        <img alt="img_1" src="./assets/example/img_9.jpg" className="img_example" />
                    </div>
                )
        }
    }
    
    return(
        <div className="Page_Products_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Products
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            { namePageIdioma[0][idioma] }
                        </div>
                    </div>
                    <div className="list_opt_alt_page">
                        <div className={ idioma == 'en' ? "type_idioma idioma_selected" : "type_idioma" } onClick={ ()=>{ ClickIdioma('en') } }>en</div>
                        <div className={ idioma == 'es' ? "type_idioma idioma_selected" : "type_idioma" } onClick={ ()=>{ ClickIdioma('es') } }>es</div>
                        <button className="new_block_text">
                            <div className="">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="">
                                { namePageIdioma[1][idioma] }
                            </div>
                        </button>
                    </div> 
                </div>

                <div className="show_page_data">
                    <div className="list_input_data">
                        <div className="div_input">
                            <input type="text" className="title_page" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="60" required />
                            <span className="name_input">{ namePageIdioma[3][idioma] }</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setColor(e.target.value) } } value={ color } required />
                            <span className="name_input">{ namePageIdioma[4][idioma] }</span>
                        </div>
                        <div className="div_input">
                            <div className="show_color" style={ { backgroundColor: color } } />
                        </div>
                    </div>
                </div>

                <div className="show_page_data"> 
                    <div className="list_type_data">
                        <div className="type_title">
                            { namePageIdioma[2][idioma] }
                            {
                                idPage == 0 ? null : " - " + typePage
                            }
                        </div>
                    </div>
                    <div className="list_input_data">
                        {
                            idPage == 0 ?
                            <div className="div_input">
                                <select className="select_type" onChange={ (e)=>{ setTypePage(e.target.value) } } value={ typePage } required>
                                    <option value="">#</option>
                                    {
                                        pageList.map((key, index)=>{
                                            return(
                                                <option value={ key.key } key={ index }>{ key.name[idioma] }</option>
                                            )
                                        })
                                    }
                                </select>
                                <span className="name_input">{ namePageIdioma[5][idioma] }</span>
                            </div> : null
                        }
                    </div>
                    { CurrentPage() }
                </div>

                {
                    typePage == '' ? null : 
                    <div className="show_page_data"> 
                        <div className="list_type_data">
                            <div className="type_title">{ namePageIdioma[6][idioma] }</div>
                        </div>
                        <div className="list_input_data">
                            {
                                ShowPreviewPage(typePage)
                            }
                        </div>
                    </div> 
                }
            </form>
        </div>
    )
}