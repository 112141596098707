import { useState, useEffect } from "react";

import './Products.css';

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import { SvgDelete, SvgEdit, SvgSearch, SvgSetaDown, SvgSetaRight, SvgSetaUp } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";

import { SetListPag } from "interface/Page";
import { Reg_AltPosition } from "services/RegisterData";

export default function Page_Products(){

    const [ search, setSearch ]             = useState('');
    const [ userId, setUserId ]             = useState(GetUserData('id'));
    const [ showPageData, setShowPageData ] = useState(GetDataPage('products'));

    const [ itensPage, setItensPage ] = useState(30);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showPageData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showPageData.slice(startItens, endItens);

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('products', setShowPageData);
    }, []);
    
    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('products').forEach(item =>{
                if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.text.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowPageData(duplicate);
        }else {
            setShowPageData(GetDataPage('products'));
        }
        setSearch(value);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "products", "type" : "delete_data", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'products_details')
        SetListPag('currentPageId', idPage);
    }

    function ChangePosition(id, order, type){
        Reg_AltPosition('products', id, order, type, ()=>{ }, ()=>{ CallbackError() });
    }

    function CallbackError(){
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <div className="Page_Products">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Site - Products
                    </div>
                </div>
                {/* 
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="search..." />
                </div> 
                */}
                <div className="new_block_text" onClick={ ()=>{ PageClick(0) } }>
                    <div className="">
                        <span className="icons_menu">+</span>
                    </div>
                    <div className="">
                        bloco
                    </div>
                </div>
            </div>
            <div className="">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Page type</th>
                            <th>Name</th>
                            <th width="94" align="center">Background color</th>
                            <th width="60">Order</th>
                            <th width="90" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.map((key, index)=>{

                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ key.type_page }</td>
                                        <td>{ key.title_page }</td>
                                        <td align="center">
                                            <div className="show_color" style={ { backgroundColor: key.color } } />
                                        </td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                {
                                                    index == 0 ?
                                                    <div className="space_div_icon" /> :
                                                    <div data-tooltip-id={ "position_" + key.id } data-tooltip-content="Change position" data-tooltip-place="top" className="space_div_icon" onClick={ ()=>{ ChangePosition(key.id, key.order_, 'up') } }>
                                                        <SvgSetaUp color="#324d6b" className="icons"/>
                                                    </div>
                                                }
                                                {
                                                    (index + 1) == currentItens.length ?
                                                    <div className="space_div_icon" /> :
                                                    <div data-tooltip-id={ "position_" + key.id } data-tooltip-content="Change position" data-tooltip-place="top" className="space_div_icon" onClick={ ()=>{ ChangePosition(key.id, key.order_, 'down') } }>
                                                        <SvgSetaDown color="#324d6b" className="icons"/>
                                                    </div>
                                                }
                                                <Tooltip id={ "position_" + key.id } />
                                            </div>
                                        </td>
                                        <td align="right">
                                            <div className="list_opt">
                                                <div onClick={ ()=>{ DeleteData(key.id, key.name) } } title="Delete block">
                                                    <SvgDelete color="#F00000" className="icons"/>
                                                </div>
                                                <div onClick={ ()=>{ PageClick(key.id) } } title="Edit block">
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    showPageData.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}