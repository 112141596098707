import { useState, useEffect } from "react";

import './OurServices.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgDelete, SvgImg } from "components/SvgFile";

export default function Model_OurServices(props){

    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    // en
    const [ enTitle, setEnTitle ]       = useState(InitialData('en_contents', 'en_title'));
    const [ enSubtitle, setEnSubtitle ] = useState(InitialData('en_contents', 'en_subtitle'));
    const [ enText, setEnText ]         = useState(InitialData('en_contents', 'en_text'));
    const [ enContents, setEnContents ] = useState(InitialData('en_contents', 'en_contents'));

    // es
    const [ esTitle, setEsTitle ]       = useState(InitialData('es_contents', 'es_title'));
    const [ esSubtitle, setEsSubtitle ] = useState(InitialData('es_contents', 'es_subtitle'));
    const [ esText, setEsText ]         = useState(InitialData('es_contents', 'es_text'));
    const [ esContents, setEsContents ] = useState(InitialData('es_contents', 'es_contents'));

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        // en
        setEnTitle(InitialData('en_contents', 'en_title'));
        setEnSubtitle(InitialData('en_contents', 'en_subtitle'));
        setEnText(InitialData('en_contents', 'en_text'));
        setEnContents(InitialData('en_contents', 'en_contents'));

        // es
        setEsTitle(InitialData('es_contents', 'es_title'));
        setEsSubtitle(InitialData('es_contents', 'es_subtitle'));
        setEsText(InitialData('es_contents', 'es_text')); 
        setEsContents(InitialData('es_contents', 'es_contents'));
    }, [dataPage, idPage]);

    function InitialData(key, type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[key][type];
        }
        if(type == 'en_contents' || type == 'es_contents'){
            return [];
        }
        return "";
    }

    function HandleData(type, value){
        switch (type) {
            case "enSubtitle":
                    setEnSubtitle(value);
                    props.RegisterData('en', { "en_subtitle": value, "en_title": enTitle, "en_text": enText, "en_contents": enContents });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText, "es_contents": esContents });
                break;
            case "enTitle":
                    setEnTitle(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": value, "en_text": enText, "en_contents": enContents });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText, "es_contents": esContents });
                break;
            case "enText":
                    setEnText(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": value, "en_contents": enContents });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText, "es_contents": esContents });
                break;

            case "esSubtitle":
                    setEsSubtitle(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText , "en_contents": enContents});
                    props.RegisterData('es', { "es_subtitle": value, "es_title": esTitle, "es_text": esText, "es_contents": esContents });
                break;
            case "esTitle":
                    setEsTitle(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "en_contents": enContents });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": value, "es_text": esText, "es_contents": esContents });
                break;                
            case "esText":
                    setEsText(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "en_contents": enContents });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": value, "es_contents": esContents });
                break;
        }
    }

    function HandleDataContents(type, index, value){
        // en
        if(idioma == 'en'){
            const newData = [...enContents];
            newData[index][type] = value;
            
            props.RegisterData('en', { "en_subtitle": value, "en_title": enTitle, "en_text": enText, "en_contents": newData });
            setEnContents(newData);
        }
        // es
        if(idioma == 'es'){
            const newData = [...esContents];
            newData[index][type] = value;
            
            props.RegisterData('es', { "es_subtitle": value, "es_title": enTitle, "es_text": enText, "es_contents": newData });
            setEsContents(newData);
        }
    }

    function NewContent(){
        if(idioma == 'en'){
            setEnContents([...enContents, { "id": 0, "title": "", "text": "", "status": false }]);  
        }

        if(idioma == 'es'){
            setEsContents([...esContents, { "id": 0, "title": "", "text": "", "status": false }]);              
        }
    }

    function DeleteData(id, index, value){
        if(id == 0){            
            if(idioma == 'en'){
                const newData = [...enContents];
                newData.splice(index, 1);
                setEnContents(newData);       
            }

            if(idioma == 'es'){
                const newData = [...esContents];
                newData.splice(index, 1);
                setEsContents(newData);          
            }
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "site", "type": "delete_contents", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    return(
        <div className="Model_OurServices">
            <div className="list_input_data">
                <div className="div_input space_div show_data_text">
                    {
                        idioma == 'en' ?
                            <>
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <input type="text" onChange={ (e)=>{ HandleData('enSubtitle', e.target.value) } } value={ enSubtitle } />
                                        <span className="name_input">Caption</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('enTitle', e.target.value) } } value={ enTitle } required />
                                        <span className="name_input">Title</span>
                                    </div>
                                </div>

                                <div className="list_input_data">
                                    <div className="div_input space_div show_data_text">
                                        <div className="show_title">Text</div>
                                        <JoditEditor className="height_textarea" config={ config } value={ enText !='' ? enText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('enText', newContent) } required />
                                    </div>
                                </div>

                                <div className="list_input_data">
                                    <div className="div_input space_div show_data_text">
                                        <div className="div_subtitle_add">
                                            <div className="">
                                                Explanations
                                            </div>
                                            <div className="new_data_page" onClick={ ()=>{ NewContent() } }>
                                                <span className="icons_menu">+</span> explanation
                                            </div>                                  
                                        </div>
                                        {
                                            enContents.map((key, index)=>{
                                                return(           
                                                    <div className="list_contents" key={ index } style={ { flexDirection: 'column', gap: "0px" } }>
                                                        <div className="list_input_data">
                                                            <div className="div_input space_div">
                                                                <input type="text" onChange={ (e)=>{ HandleDataContents('title', index, e.target.value) } } value={ key.title } />
                                                                <span className="name_input">Title</span>
                                                            </div>
                                                            <div className="div_input show_icons" onClick={ ()=>{ DeleteData(key.id, index, key.name) } } style={ { display: 'flex' } }>
                                                                <SvgDelete color="#f00000" className="icons" />
                                                            </div>
                                                        </div>
                                                        <div className="list_input_data">
                                                            <div className="div_input space_div show_data_text">
                                                                <div className="show_title">Text</div>
                                                                <JoditEditor className="height_textarea" config={ config } value={ key.text !='' ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, newContent) } />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </> :
                            <>
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <input type="text" onChange={ (e)=>{ HandleData('esSubtitle', e.target.value) } } value={ esSubtitle } />
                                        <span className="name_input">Subtítulo</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('esTitle', e.target.value) } } value={ esTitle } required />
                                        <span className="name_input">Título</span>
                                    </div>
                                </div>

                                <div className="list_input_data">
                                    <div className="div_input space_div show_data_text">
                                        <div className="show_title">Texto</div>
                                        <JoditEditor className="height_textarea" config={ config } value={ esText !='' ? esText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('esText', newContent) } required />
                                    </div>
                                </div>

                                <div className="list_input_data">
                                    <div className="div_input space_div show_data_text">
                                        <div className="div_subtitle_add">
                                            <div className="">
                                                Explicaciones
                                            </div>
                                            <div className="new_data_page" onClick={ ()=>{ NewContent() } }>
                                                <span className="icons_menu">+</span> explicaciones
                                            </div>                                  
                                        </div>
                                        {
                                            esContents.map((key, index)=>{
                                                return(           
                                                    <div className="list_contents" key={ index } style={ { flexDirection: 'column' } }>
                                                        <div className="list_input_data">
                                                            <div className="div_input space_div">
                                                                <input type="text" onChange={ (e)=>{ HandleDataContents('title', index, e.target.value) } } value={ key.title } />
                                                                <span className="name_input">Title</span>
                                                            </div>
                                                            <div className="div_input show_icons" onClick={ ()=>{ DeleteData(key.id, index, key.name) } } style={ { display: 'flex' } }>
                                                                <SvgDelete color="#f00000" className="icons" />
                                                            </div>
                                                        </div>
                                                        <div className="list_input_data">
                                                            <div className="div_input space_div show_data_text">
                                                                <div className="show_title">Text</div>
                                                                <JoditEditor className="height_textarea" config={ config } value={ key.text !='' ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleDataContents('text', index, newContent) } />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}