import { useState, useEffect } from "react";

import './Video.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgImg, SvgVideo, SvgVimeo, SvgYoutube } from "components/SvgFile";

export default function Model_Video(props){

    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    // en
    const [ enTitle, setEnTitle ]       = useState(InitialData('en_contents', 'en_title'));
    const [ enSubtitle, setEnSubtitle ] = useState(InitialData('en_contents', 'en_subtitle'));
    const [ enFile, setEnFile ]         = useState(InitialData('en_contents', 'en_file'));
    const [ enTypeFile, setEnTypeFile ] = useState(InitialData('en_contents', 'en_type_file'));

    // es
    const [ esTitle, setEsTitle ]       = useState(InitialData('es_contents', 'es_title'));
    const [ esSubtitle, setEsSubtitle ] = useState(InitialData('es_contents', 'es_subtitle'));
    const [ esFile, setEsFile ]         = useState(InitialData('es_contents', 'es_file'));
    const [ esTypeFile, setEsTypeFile ] = useState(InitialData('es_contents', 'es_type_file'));

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        // en
        setEnTitle(InitialData('en_contents', 'en_title'));
        setEnSubtitle(InitialData('en_contents', 'en_subtitle'));
        setEnFile(InitialData('en_contents', 'en_file'));
        setEnTypeFile(InitialData('en_contents', 'en_type_file'));

        // es
        setEsTitle(InitialData('es_contents', 'es_title'));
        setEsSubtitle(InitialData('es_contents', 'es_subtitle'));
        setEsFile(InitialData('es_contents', 'es_file'));
        setEsTypeFile(InitialData('es_contents', 'es_type_file'));
    }, [dataPage, idPage]);

    function InitialData(key, type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[key][type];
        }
        if(type == 'en_type_file' || type == 'es_type_file'){
            return 'Vimeo';
        }
        return "";
    }

    function HandleData(type, value){
        switch (type) {
            case "enSubtitle":
                    setEnSubtitle(value);
                    props.RegisterData('en', { "en_subtitle": value, "en_title": enTitle, "en_file": enFile, "en_type_file": enTypeFile });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": esTitle, "es_file": esFile, "es_type_file": esTypeFile });
                break;
            case "enTitle":
                    setEnTitle(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": value, "en_file": enFile, "en_type_file": enTypeFile });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": esTitle, "es_file": esFile, "es_type_file": esTypeFile });
                break;
            case "enFile":
                    setEnFile(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": enTitle, "en_file": value, "en_type_file": enTypeFile });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": esTitle, "es_file": esFile, "es_type_file": esTypeFile });
                break;
            case "enTypeFile":
                    setEnTypeFile(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": enTitle, "en_file": enFile, "en_type_file": value });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": esTitle, "es_file": esFile, "es_type_file": esTypeFile });
                break;

            case "esSubtitle":
                    setEsSubtitle(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": enTitle, "en_file": enFile, "en_type_file": enTypeFile });
                    props.RegisterData('es', { "es_subtitle": value, "es_title": esTitle, "es_file": esFile, "es_type_file": esTypeFile });
                break;
            case "esTitle":
                    setEsTitle(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": enTitle, "en_file": enFile, "en_type_file": enTypeFile });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": value, "es_file": esFile, "es_type_file": esTypeFile });
                break;                
            case "esFile":
                    setEsFile(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": enTitle, "en_file": enFile, "en_type_file": enTypeFile });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": esTitle, "es_file": value, "es_type_file": esTypeFile });
                break;
            case "esTypeFile":
                    setEsTypeFile(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": enTitle, "en_file": enFile, "en_type_file": enTypeFile });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": esTitle, "es_file": esFile, "es_type_file": value });
                break;
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="Model_Text">
            <div className="list_input_data">
                <div className="div_input space_div show_data_text">
                    {
                        idioma == 'en' ?
                            <>
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('enSubtitle', e.target.value) } } value={ enSubtitle } />
                                        <span className="name_input">Caption</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('enTitle', e.target.value) } } value={ enTitle } />
                                        <span className="name_input">Title</span>
                                    </div>
                                </div>
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <select className="" onChange={ (e)=>{ HandleData('enTypeFile', e.target.value); } } value={ enTypeFile }>
                                            <option value="Vimeo">Vídeo - Vimeo</option>
                                            <option value="Youtube">Vídeo - Youtube</option>
                                        </select>
                                        <span className="name_input">Type</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('enFile', e.target.value) } } value={ enFile } />
                                        <span className="name_input">Video code</span>
                                    </div>
                                    {
                                        enFile == '' ? null :
                                        <div className="div_input space_top" onClick={ ()=>{ OpenFile(enTypeFile, enFile) } }>
                                            {
                                                enTypeFile == 'Vimeo' ? 
                                                <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                    enTypeFile == 'Youtube' ? 
                                                    <SvgYoutube color="#324d6b" className="show_icons_project"/> : null
                                            }
                                            </div>
                                    }
                                </div>
                            </> :
                            <>
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('esSubtitle', e.target.value) } } value={ esSubtitle } />
                                        <span className="name_input">Subtítulo</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('esTitle', e.target.value) } } value={ esTitle } />
                                        <span className="name_input">Título</span>
                                    </div>
                                </div>
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <select className="" onChange={ (e)=>{ HandleData('esTypeFile', e.target.value); } } value={ esTypeFile }>
                                            <option value="Vimeo">Vídeo - Vimeo</option>
                                            <option value="Youtube">Vídeo - Youtube</option>
                                        </select>
                                        <span className="name_input">Tipo</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('esFile', e.target.value) } } value={ esFile } />
                                        <span className="name_input">Video code</span>
                                    </div>
                                    {
                                        esFile == '' ? null :
                                        <div className="div_input space_top" onClick={ ()=>{ OpenFile(esTypeFile, esFile) } }>
                                            {
                                                esTypeFile == 'Vimeo' ? 
                                                <SvgVimeo color="#324d6b" className="show_icons_project"/> :
                                                    esTypeFile == 'Youtube' ? 
                                                    <SvgYoutube color="#324d6b" className="show_icons_project"/> : null
                                            }
                                            </div>
                                    }
                                </div>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}