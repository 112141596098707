
let DataPage = {
    "dataConfig" : [],
    "accessDash" : [],
    "home"       : [],
    "about"      : [],
    "work"       : [],
    "products"   : [],
    "portfolio"  : []
};

let NotifyDataPage = {
    "dataConfig" : [],
    "accessDash" : [],
    "home"       : [],
    "about"      : [],
    "work"       : [],
    "products"   : [],
    "portfolio"  : [],
    "service"    : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["dataConfig"].forEach(element => {
        element(value['dataConfig']);
    });
    NotifyDataPage["accessDash"].forEach(element => {
        element(value['accessDash']);
    });
    NotifyDataPage["home"].forEach(element => {
        element(value['home']);
    });
    NotifyDataPage["about"].forEach(element => {
        element(value['about']);
    });
    NotifyDataPage["work"].forEach(element => {
        element(value['work']);
    });
    NotifyDataPage["products"].forEach(element => {
        element(value['products']);
    });
    NotifyDataPage["portfolio"].forEach(element => {
        element(value['portfolio']);
    });
    NotifyDataPage["service"].forEach(element => {
        element(value['service']);
    });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}
