import { useState, useEffect } from "react";

import './Portfolio.css';

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import { SvgChekedReturn, SvgClose, SvgCopy, SvgDelete, SvgEdit, SvgSearch, SvgSetaRight, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";

import PopUP_CopyData from "components/PopUp/CopyData";

export default function Page_Portfolio(){

    const [ search, setSearch ]             = useState('');
    const [ userId, setUserId ]             = useState(GetUserData('id'));
    const [ showPageData, setShowPageData ] = useState(GetDataPage('portfolio'));

    const [ itensPage, setItensPage ] = useState(30);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showPageData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showPageData.slice(startItens, endItens);

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('portfolio', setShowPageData);
    }, []);

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('portfolio').forEach(item =>{
                if(item.en_client.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.en_project.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowPageData(duplicate);
        }else {
            setShowPageData(GetDataPage('portfolio'));
        }
        setSearch(value);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'portfolio_details')
        SetListPag('currentPageId', idPage);
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function ShowBackground(type, file){
        switch (type) {
            case "Youtube":
                return(
                    <div className="show_bg_click" onClick={ ()=>{ OpenFile(type, file) } }>
                        <SvgYoutube color="#324d6b" className="icons" />
                    </div>
                );

            case "Vimeo":
                return(
                    <div className="show_bg_click" onClick={ ()=>{ OpenFile(type, file) } }>
                        <SvgVimeo color="#324d6b" className="icons" />
                    </div>
                );
            
            case "Image":
                return(
                    <div className="show_bg_click" onClick={ ()=>{ OpenFile('showImg', "portfolio/" + file) } }>
                        <img alt="img" src={ "./assets/portfolio/" + file } className="show_bg" />
                    </div>
                );
        }
    }

    function CopyPortfolio(id, value){
        SetModalData('CopyData', { "id": id, "type_page": "portfolio", "name_project": value });
        SetModalState('CopyData', true);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "portfolio", "type": "delete_data", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function CallbackError(){
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <div className="Page_Portfolio">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Site - Portfolio
                    </div>
                </div>
                
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="search..." />
                </div> 
               
                <div className="new_block_text" onClick={ ()=>{ PageClick(0) } }>
                    <div className="">
                        <span className="icons_menu">+</span>
                    </div>
                    <div className="">
                        portfolio
                    </div>
                </div>
            </div>
            <div className="">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th width="120">Client</th>
                            <th>Project</th>
                            <th width="94" align="center">Show<br />for gallery</th>
                            <th width="94" align="center">Background</th>
                            <th width="94" align="center">Background color</th>
                            <th width="90" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.map((key, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ key.en_client }</td>
                                        <td dangerouslySetInnerHTML={ { __html: key.en_project ? key.en_project.replaceAll('&#34;', '"') : "" } } />
                                        <td align="center">
                                            {
                                                key.en_show_gallery == true ? 
                                                <SvgChekedReturn className="icons" color="#43a047" /> :
                                                <SvgClose className="icons" color="#f00000" />
                                            }
                                        </td>
                                        <td align="center">
                                            { ShowBackground(key.en_type_cover, key.en_file) }
                                        </td>
                                        <td align="center">
                                            <div className="show_color" style={ { backgroundColor: key.en_color } } />
                                        </td>
                                        <td align="right">
                                            <div className="list_opt">
                                                <div onClick={ ()=>{ PageClick(key.id) } } title="Edit portfolio">
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                                <div onClick={ ()=>{ CopyPortfolio(key.id, key.en_project) } } title="Copy portfolio">
                                                    <SvgCopy color="#324d6b" className="icons"/>
                                                </div>
                                                <div onClick={ ()=>{ DeleteData(key.id, key.en_project) } } title="Delete portfolio">
                                                    <SvgDelete color="#F00000" className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    showPageData.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
            <PopUP_CopyData />
        </div>
    )
}