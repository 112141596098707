import React, { useState, useEffect } from "react";

import './CopyData.css';

import { SvgClose } from "components/SvgFile";

import { GetUserData, RegisterUserData } from "interface/Users";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { Reg_CopyData } from "services/RegisterData";

export default function PopUP_CopyData(){

    const [ loading, setLoading ]     = useState(false);
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ userId, setUserId ]       = useState(GetUserData('id'));

    const [ id, setId ]                  = useState('');
    const [ name, setName ]              = useState('');
    const [ nameProject, setNameProject] = useState('');

    useEffect(()=>{
        RegisterUserData('id', setUserId);

        RegisterModalData('CopyData', setModaldata);
        RegisterModalObserver('CopyData', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setId(modalData.id);
            setNameProject(modalData.name_project);
        }
    }, [showPopUp]);

    function SaveData(){
        Reg_CopyData(modalData.type_page, userId, id, name, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        ClosePopUp();
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        setId('');
        setName('');
        SetModalState('CopyData', false);
    }

    return (
        (showPopUp ?
            <>
                <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                    <img alt="loading" src="./assets/loading.gif" className="loading" />
                </div>
                <div className="PopUp">
                    <div className="all popup_copy_data">
                        <div className="div_data type_div">
                            <div className="title">
                                Copy Dados
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="list_input_data">
                                    <div className="div_input space_div" dangerouslySetInnerHTML={ { __html: nameProject ? nameProject.replaceAll('&#34;', '"') : "" } } />
                                </div> 
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" className="space_div" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" />
                                        <span className="name_input">Name</span>
                                    </div>
                                </div>
                                <div className="register_data button_category space_div" onClick={ ()=>{ SaveData() } }>
                                    Register
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
