import { useState, useEffect } from "react";

import './Text.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgImg } from "components/SvgFile";

export default function Model_Text(props){

    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    // en
    const [ enTitle, setEnTitle ]       = useState(InitialData('en_contents', 'en_title'));
    const [ enSubtitle, setEnSubtitle ] = useState(InitialData('en_contents', 'en_subtitle'));
    const [ enText, setEnText ]         = useState(InitialData('en_contents', 'en_text'));
    const [ enLink, setEnLink ]         = useState(InitialData('en_contents', 'en_link'));
    const [ enBgTitle, setEnBgTitle ]   = useState(InitialData('en_contents', 'en_bg_title'));

    // es
    const [ esTitle, setEsTitle ]       = useState(InitialData('es_contents', 'es_title'));
    const [ esSubtitle, setEsSubtitle ] = useState(InitialData('es_contents', 'es_subtitle'));
    const [ esText, setEsText ]         = useState(InitialData('es_contents', 'es_text'));
    const [ esLink, setEsLink ]         = useState(InitialData('es_contents', 'es_link'));
    const [ esBgTitle, setEsBgTitle ]   = useState(InitialData('es_contents', 'es_bg_title'));

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        // en
        setEnTitle(InitialData('en_contents', 'en_title'));
        setEnSubtitle(InitialData('en_contents', 'en_subtitle'));
        setEnText(InitialData('en_contents', 'en_text'));
        setEnLink(InitialData('en_contents', 'en_link'));
        setEnBgTitle(InitialData('en_contents', 'en_bg_title'));

        // es
        setEsTitle(InitialData('es_contents', 'es_title'));
        setEsSubtitle(InitialData('es_contents', 'es_subtitle'));
        setEsText(InitialData('es_contents', 'es_text')); 
        setEsLink(InitialData('en_contents', 'es_link'));
        setEsBgTitle(InitialData('en_contents', 'es_bg_title'));
    }, [dataPage, idPage]);

    function InitialData(key, type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[key][type];
        }
        if(type == 'en_bg_title' || type == 'es_bg_title'){
            return 'Yes'
        }
        return "";
    }

    function HandleData(type, value){
        switch (type) {
            case "enSubtitle":
                    setEnSubtitle(value);
                    props.RegisterData('en', { "en_subtitle": value, "en_title": enTitle, "en_text": enText, "en_link": enLink, "en_bg_title": enBgTitle });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText, "es_link": esLink, "es_bg_title": esBgTitle });
                break;
            case "enTitle":
                    setEnTitle(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": value, "en_text": enText, "en_link": enLink, "en_bg_title": enBgTitle });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText, "es_link": esLink, "es_bg_title": esBgTitle });
                break;
            case "enText":
                    setEnText(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": value, "en_link": enLink, "en_bg_title": enBgTitle });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText, "es_link": esLink, "es_bg_title": esBgTitle });
                break;
            case "enLink":
                    setEnLink(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "en_link": value, "en_bg_title": enBgTitle });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText, "es_link": esLink, "es_bg_title": esBgTitle });
                break;
            case "enBgTitle":
                    setEnBgTitle(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "en_link": enLink, "en_bg_title": value });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText, "es_link": esLink, "es_bg_title": esBgTitle });
                break;

            case "esSubtitle":
                    setEsSubtitle(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "en_link": enLink, "en_bg_title": enBgTitle });
                    props.RegisterData('es', { "es_subtitle": value, "es_title": esTitle, "es_text": esText, "es_link": esLink, "es_bg_title": esBgTitle });
                break;
            case "esTitle":
                    setEsTitle(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "en_link": enLink, "en_bg_title": enBgTitle });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": value, "es_text": esText, "es_link": esLink, "es_bg_title": esBgTitle });
                break;                
            case "esText":
                    setEsText(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "en_link": enLink, "en_bg_title": enBgTitle });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": value, "es_link": esLink, "es_bg_title": esBgTitle });
                break;           
            case "esLink":
                    setEsLink(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "en_link": enLink, "en_bg_title": enBgTitle });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText, "es_link": value, "es_bg_title": esBgTitle });
                break;         
            case "esBgTitle":
                    setEsBgTitle(value);
                    props.RegisterData('en', { "en_subtitle": enSubtitle, "en_title": enTitle, "en_text": enText, "en_link": enLink, "en_bg_title": enBgTitle });
                    props.RegisterData('es', { "es_subtitle": esSubtitle, "es_title": esTitle, "es_text": esText, "es_link": esLink, "es_bg_title": value });
                break;
        }
    }

    return(
        <div className="Model_Text">
            <div className="list_input_data">
                <div className="div_input space_div show_data_text">
                    {
                        idioma == 'en' ?
                            <>
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <input type="text" onChange={ (e)=>{ HandleData('enSubtitle', e.target.value) } } value={ enSubtitle } />
                                        <span className="name_input">Caption</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('enTitle', e.target.value) } } value={ enTitle } />
                                        <span className="name_input">Title</span>
                                    </div>
                                    <div className="div_input">
                                        <select className="select_bg" onChange={ (e)=>{ HandleData('enBgTitle', e.target.value); } } value={ enBgTitle }>
                                            <option value="Yes">Yes</option>
                                            <option value="Not">Not</option>
                                        </select>
                                        <span className="name_input">Background title</span>
                                    </div>
                                </div>
                                {
                                    props.type == 1 ? null :
                                    <div className="list_input_data">
                                        <div className="div_input space_div">
                                            <input type="text" onChange={ (e)=>{ HandleData('enLink', e.target.value) } } value={ enLink } required />
                                            <span className="name_input">link</span>
                                        </div>
                                    </div> 
                                }

                                <div className="list_input_data">
                                    <div className="div_input space_div show_data_text">
                                        <div className="show_title">Text</div>
                                        <JoditEditor className="height_textarea" config={ config } value={ enText !='' ? enText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('enText', newContent) } required />
                                    </div>
                                </div>
                            </> :
                            <>
                                <div className="list_input_data">
                                    <div className="div_input">
                                        <input type="text" onChange={ (e)=>{ HandleData('esSubtitle', e.target.value) } } value={ esSubtitle } />
                                        <span className="name_input">Subtítulo</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('esTitle', e.target.value) } } value={ esTitle } />
                                        <span className="name_input">Título</span>
                                    </div>
                                    <div className="div_input">
                                        <select className="select_bg" onChange={ (e)=>{ HandleData('esBgTitle', e.target.value); } } value={ esBgTitle }>
                                            <option value="Yes">Sí</option>
                                            <option value="Not">No</option>
                                        </select>
                                        <span className="name_input">Título de fondo</span>
                                    </div>
                                </div>
                                {
                                    props.type == 1 ? null :
                                    <div className="list_input_data">
                                        <div className="div_input space_div">
                                            <input type="text" onChange={ (e)=>{ HandleData('esLink', e.target.value) } } value={ esLink } required />
                                            <span className="name_input">link</span>
                                        </div>
                                    </div>
                                }

                                <div className="list_input_data">
                                    <div className="div_input space_div show_data_text">
                                        <div className="show_title">Texto</div>
                                        <JoditEditor className="height_textarea" config={ config } value={ esText !='' ? esText.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('esText', newContent) } required />
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}