
import { useState } from "react";

import './Login.css';

import { SvgLogoTipo } from "components/SvgFile";

import { Access } from "services/AccessLogin";

export default function Login(){

    const [ email, setEmail ]       = useState('');
    const [ password, setPassword ] = useState('');
    const [ msgError, setMsgError ] = useState('');

    function saveData(event){
        event.preventDefault();
        Access('logar', email, password, ()=>{ }, CallbackError);
    }

    function CallbackError(){
        setMsgError('E-mail ou senha incorreta!');
    }

    return(
        <div className="Login">
            <form onSubmit={ saveData }>
                <div className="content">
                    <div className="div_logo" data-color="#000000">
                        <SvgLogoTipo color="#324D6B" className="logo" />
                    </div>
                    <div className="div_input">
                        <input type="email" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } required placeholder="exemplo@site.com.br" />
                        <span className="name_input">Email*</span>
                    </div>
                    <div className="div_input div_input_login">
                        <input type="password" onChange={ (e)=>{ setPassword(e.target.value) } } value={ password } required placeholder="******" />
                        <span className="name_input">Password*</span>
                    </div>
                    <div className={ msgError ? "msg_error" : "" }>
                        { msgError }
                    </div>
                    <div className="div_logar">
                        <button type="submit" className="button">
                            Logar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}
