import React, { useState, useEffect, useRef } from 'react';

import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import './PhotoCrop.css';

import { SetModalData, SetModalState, RegisterModalData, RegisterModalObserver } from 'interface/PopUp.js';
import { SvgClose } from 'components/SvgFile';

export default function PopUp_PhotoCrop(){

    const positionImg = useRef();
    
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ typeCrop, setTypeCrop ]   = useState(1);

    const [ proportionX, setProportionX ] = useState(9);
    const [ proportionY, setProportionY ] = useState(16);

    const [ crop, setCrop ] = useState({
        unit: '%', // Can be 'px' or '%'
        aspect: proportionX / proportionY,
        x: 0,
        y: 0,
        width: 0,
        height: 0
    });

    useEffect(()=>{
        RegisterModalData('PhotoCrop', setModaldata);
        RegisterModalObserver('PhotoCrop', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            if(modalData.origin != "portfolio" || modalData.origin != "service"){
                setProportionX(16);
                setProportionY(9);
                setTypeCrop(2);
            }else {
                setTypeCrop(3);
            }
        }
    }, [showPopUp]);

    async function cropImageNow (){
        modalData.RegisterCropData(
            { 
                "type"      : modalData.origin,
                "index"     : modalData.index, 
                "index_1"   : modalData.index_1,
                "upload"    : true,
                "file"      : modalData.file, 
                "showImg"   : modalData.showImg, 
                "positionX" : crop.x, 
                "positionY" : crop.y, 
                "width"     : crop.width, 
                "height"    : crop.height,
                "typeCrop"  : typeCrop,
                "showWidth" : positionImg.current.clientWidth,
                "widthReal" : positionImg.current.naturalWidth
            }
        );        
        setTypeCrop(1);
        SetModalState('PhotoCrop', false);
    };
 
    // Starts cropping at 9/16 ratio centered on image
    function onImageLoad(e) {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
        let crop = "";
        modalData.origin == "portfolio" || modalData.origin == "service" ?
        crop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                }
            ),
            width,
            height
        ) :
        crop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                }
            ),
            width,
            height
        )  
        setCrop(crop);
    }

    function ClosePopUp(){
        setTypeCrop(1);
        SetModalState('PhotoCrop', false);
    }

    return (
        (showPopUp ? 
            <div className="PopUp">
                <div className="all photo_crop">
                    <div className="div_data type_div">
                        <div className="title">Photo clipping</div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <SvgClose color="#F00000" className="icons" />
                        </div>
                    </div>
                    <div className="div_data" style={ { paddingTop: 0 } }>
                        <div className="content">
                            {
                                modalData.origin == "cover" ?
                                    crop.width > 0 ? 
                                    <div className="new_block_text" onClick={ ()=>{ cropImageNow() } }>To save</div> :
                                    <div className="new_block_text">You need to crop the image to save</div>
                                    :
                                    typeCrop == 3 ? <div className="new_block_text" onClick={ ()=>{ cropImageNow() } }>To save</div> :
                                        crop.width > 0 ? <div className="new_block_text" onClick={ ()=>{ cropImageNow() } }>To save</div> :
                                        <div className="new_block_text">You need to crop the image to save</div>
                            }
                            <div className="">Click on the image and drag to perform the crop</div>
                            <div className="image_crop">
                                { 
                                    modalData.origin == "portfolio" || modalData.origin == "service" ? 
                                    <div className="type_crop">
                                        <div className={ typeCrop == 3 ? "crop_img crop_img_active" : "crop_img" } onClick={ ()=>{ setTypeCrop(3); } }>
                                            No clipping
                                        </div>
                                        <div className={ typeCrop == 1 ? "crop_img crop_img_active" : "crop_img" } onClick={ ()=>{ setTypeCrop(1); setProportionX(16); setProportionY(9); } }>
                                            Horizontal cut
                                        </div>
                                        <div className={ typeCrop == 2 ? "crop_img crop_img_active" : "crop_img" } onClick={ ()=>{ setTypeCrop(2); setProportionX(9); setProportionY(16); } }>
                                            Vertical cut
                                        </div>                                    
                                    </div> : null
                                }
                                {
                                    typeCrop == 3 ?
                                    <img ref={ positionImg } src={ modalData.showImg } className="image" /> :
                                    <ReactCrop crop={ crop } onChange={ setCrop } aspect={ proportionX / proportionY }>
                                        <img ref={ positionImg } src={ modalData.showImg } onLoad={ onImageLoad } className="image" />
                                    </ReactCrop>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div> 
        : <React.Fragment></React.Fragment>)
    );
}